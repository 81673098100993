import React, { useState } from 'react';
import { Tag } from './TagsManager';
import TagCard from './TagCard';

type VisibilityFilter = "client" | "cleaner" | "admin";

type TagProps = {
  tags: Tag[];
  onEdit: (tag: Tag) => void;
  onDelete: (id: number) => void;
}

const TagList: React.FC<TagProps> = ({ tags, onEdit, onDelete }) => {
  const [visibilityFilters, setVisibilityFilters] = useState<Set<VisibilityFilter>>(new Set());

  const toggleFilter = (filter: VisibilityFilter) => {
    const newFilters = new Set(visibilityFilters);
    if (newFilters.has(filter)) {
      newFilters.delete(filter);
    } else {
      newFilters.add(filter);
    }
    setVisibilityFilters(newFilters);
  };

  const filteredTags = tags.filter(tag => {
    if (visibilityFilters.size === 0) return true;
    return (
      (visibilityFilters.has("client") && tag.client_visible) ||
      (visibilityFilters.has("cleaner") && tag.cleaner_visible) ||
      (visibilityFilters.has("admin") && tag.admin_visible)
    );
  });

  return (
    <div className="space-y-4">
      <div className="flex gap-2 mb-4">
        <button
          onClick={() => setVisibilityFilters(new Set())}
          className={`px-3 py-1 rounded-md text-sm border border-gray-300 ${visibilityFilters.size === 0
              ? "bg-lime-600 text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
        >
          All
        </button>
        <button
          onClick={() => toggleFilter("client")}
          className={`px-3 py-1 rounded-md text-sm border border-gray-300 ${visibilityFilters.has("client")
              ? "bg-lime-600 text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
        >
          Client Visible
        </button>
        <button
          onClick={() => toggleFilter("cleaner")}
          className={`px-3 py-1 rounded-md text-sm border border-gray-300 ${visibilityFilters.has("cleaner")
              ? "bg-lime-600 text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
        >
          Cleaner Visible
        </button>
        <button
          onClick={() => toggleFilter("admin")}
          className={`px-3 py-1 rounded-md text-sm border border-gray-300 ${visibilityFilters.has("admin")
              ? "bg-lime-600 text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
        >
          Admin Visible
        </button>
      </div>

      {filteredTags.map((tag) => (
        <TagCard key={tag.id} tag={tag} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </div>
  );
}

export default TagList;
