import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  CheckCircleIcon,
  EmojiSadIcon,
  LightBulbIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
  XCircleIcon
} from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { get, post, put } from '../../helper/fetch';
import dayjs from 'dayjs';
import CleanerMap from '../../components/CleanerMap/CleanerMap';
import CleanerAvailability from '../../components/CleanerAvailability/CleanerAvailability';
import { DrawerContext } from '../../context/DrawerContext';
import { toast } from 'react-toastify';
import { Cleaner, ClientReview, Complaint, CleanerStats } from '../../settings/bookingTypes';
import EditCleanerAvailability from '../../components/EditCleanerAvailability/EditCleanerAvailability';
import CleanerReviewCard from '../../components/CleanerReviewCard/CleanerReviewCard';
import EditCleanerReviewCard from '../../components/EditCleanerReviewCard/CleanerReviewCard';
import { AUTH_HEADER } from '../../settings/constants';
import Firebase from '../../helper/firebase';
import DropDownBasic from '../../components/DropDownBasic/DropDownBasic';
import Reviews from './Reviews';
import { copyToClipboard } from '../../helper/util';
import { Tag } from '../Tags/TagsManager';
import { CleanerTag } from '../../types/tags';
import { TagSelector } from "../../components/TagSelector/TagSelector";
import AreaMapPicker from '../../components/AreaMapPicker';
import { QuillEditor } from '../../components/QuillEditor';

const user = {
  name: 'Whitney Francis',
  email: 'whitney@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80'
};

const firebase = Firebase.getInstance();

const comments = [];

const getStatus = (status): ReactElement => {
  switch (status) {
    case 'accepted': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'requested': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <LightBulbIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-yellow-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'rejected': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <XCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'cancelled':
    case 'canceled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
    }
    case 'confirmed':
    case 'completed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-2.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
    }
    default: {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500"> ??? </p>
      );
    }
  }
};

type Booking = {
  id: number;
  clientName: string;
  date: string;
  status: string;
};

export default function CleanerProfile() {
  const { id } = useParams<{ id: string }>();
  const [cleaner, setCleaner] = useState<Cleaner | null>(null);
  const [bookings, setBookings] = useState([] as Booking[]);
  const history = useHistory();
  const [showAvailability, setShowAvailability] = useState(false);
  const [editAvailability, setEditAvailability] = useState(false);
  const [editReviews, setEditReviews] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);
  const [addReviewButtonPressed, setAddReviewButtonPressed] =
    useState<boolean>(false);
  const [addComplaintButtonPressed, setAddComplaintButtonPressed] =
    useState<boolean>(false);
  const [avatar, setAvatar] = useState<File>(null);
  const [newReviewBookingId, setNewReviewBookingId] = useState<number | null>(
    null
  );
  const [newComplaint, setNewComplaint] = useState<
    Omit<Complaint, 'id' | 'created_at' | 'author_id'>
  >({
    text: '',
    type: ''
  });
  const [tiers, setTiers] = useState([]);
  const [editingTier, setEditingTier] = useState(false);
  const [tierOptions, setTierOptions] = useState([]);
  const [cleanerUrl, setCleanerUrl] = useState<string>("");
  const [tags, setTags] = useState<CleanerTag[]>([]);
  const [isTagSelectorOpen, setIsTagSelectorOpen] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [cleanerStats, setCleanerStats] = useState<CleanerStats | null>(null);
  useEffect(() => {
    if (id) {
      Promise.all([
        get(`/cleanerlistings/${id}`),
        get(`/tags/listings/${id}/tags`),
        get(`/cleanerlistings/${id}/stats`).catch(() => null)
      ])
        .then(([cleanerData, tagsData, statsData]) => {
          setCleaner(cleanerData);
          setTags(tagsData.data);
          if (statsData) {
            setCleanerStats(statsData);
          }
        })
        .catch((e) => {
          console.error("Error fetching cleaner data:", e);
          toast.error("Error loading cleaner data");
        });

      get(`/cleanerlistings/${id}/map`);

      get('/settings/tiers').then((data) => {
        setTiers(data);
        setTierOptions(
          data.map((t) => ({
            id: t.id,
            name: `${t.name} (${t.region.name})`,
            unavailable: false
          }))
        );
      });
    }
  }, [id]);

  useEffect(() => {
    if (!cleaner) {
      return;
    }
    get(`/cleanerlistings/${cleaner.listing.id}/bookings`)
      .then((r) => {
        setBookings(r);
      })
      .catch((e) => { });

  }, [cleaner]);

  useEffect(() => {
    if (cleaner) {
      let firstName;
      const fnameSplits = cleaner.given_name?.split(' ');
      if (fnameSplits.length > 1) {
        firstName = fnameSplits[0];
      } else {
        firstName = cleaner.given_name;
      }
      let lnameSplits = cleaner.family_name?.split(' ');
      let lastName;
      if (lnameSplits.length > 1) {
        lastName = lnameSplits[0];
      } else {
        lastName = cleaner.family_name;
      }
      let cleanerProfileUrl = '';
      if (lnameSplits.length > 1) {
        lastName = lnameSplits[0];
      } else {
        lastName = cleaner.family_name;
        if (lastName.length === 0) {
          cleanerProfileUrl = `https://app.homespritz.ca/cleaner/${firstName.toLowerCase()}-${cleaner.listing.id}`;
        } else {
          cleanerProfileUrl = `https://app.homespritz.ca/cleaner/${firstName.toLowerCase()}-${lastName[0].toLowerCase()}-${cleaner.listing.id}`;
        }
      }
      setCleanerUrl(cleanerProfileUrl);
    }
  }, [cleaner]);

  const updateCleaner = async (data) => {
    const resp = await put(`/cleanerlistings/${cleaner.id}`, data);
    if (resp.status !== 200) {
      toast.error('could not update cleaner');
    } else {
      toast.success('updated cleaner');
      //refetch
      get(`/cleanerlistings/${cleaner.listing.id}`)
        .then((r) => {
          setCleaner(r);
        })
        .catch((e) => { });
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };

  const notifyCleanerAboutRequests = async () => {
    const resp = await post(
      `/cleanerlistings/${cleaner.listing.id}/reminder`,
      {}
    );
    if (resp.status !== 200) {
      toast.error('could not notify cleaner');
    } else {
      toast.success('notified cleaner');
    }
  };

  async function updateCleanerMap(newCoords) {
    const resp = await put(`/cleanerlistings/${cleaner.id}/map`, newCoords);
    if (resp.status !== 200) {
      toast.error('could not update cleaner map');
    } else {
      toast.success('updated cleaner map');
    }
  }

  const handleSaveDescription = async (content: { text: string, html: string, delta: string }) => {
    try {
      await updateCleaner({
        ...cleaner,
        listing: {
          ...cleaner.listing,
          description: content.text,
          description_html: content.html,
          description_delta: content.delta
        }
      });
      setIsEditingDescription(false);
    } catch (error) {
      toast.error("Failed to save description");
    }
  };


  const renderAvailability = () => {
    if (!showAvailability) return null;
    if (editAvailability) {
      return (
        <EditCleanerAvailability
          availability={cleaner.listing?.availability}
          onSave={async (newAvailability) => {
            const resp = await put(
              `/cleanerlistings/${cleaner.id}/availability`,
              newAvailability
            );
            if (resp.status !== 200) {
              toast.error('could not update cleaner availability');
            } else {
              toast.success('updated cleaner availability');
              //refetch
              get(`/cleanerlistings/${cleaner.listing.id}`)
                .then((r) => {
                  setCleaner(r);
                })
                .catch((e) => { });
            }
          }}
        />
      );
    } else {
      return (
        <CleanerAvailability availability={cleaner.listing?.availability} />
      );
    }
  };

  const saveReviews = async (newRatings) => {
    if (!editReviews) return null;

    const resp = await put(
      `/cleanerlistings/${cleaner.listing.id}/ratings`,
      newRatings
    );
    if (resp.status !== 200) {
      toast.error('could not update cleaner ratings');
    } else {
      toast.success('updated cleaner ratings');
      //refetch
      get(`/cleanerlistings/${cleaner.listing.id}`)
        .then((r) => {
          setCleaner(r);
          setEditReviews(false);
        })
        .catch((e) => { });
    }
  };

  const addComplaint = async () => {
    try {
      const resp = await post(
        `/cleanerlistings/${cleaner.listing.id}/complaints`,
        newComplaint
      );
      if (resp.status !== 201) {
        toast.error('could not add complaint');
      } else {
        toast.success('added complaint');
        setNewComplaint({
          text: '',
          type: ''
        });
        //refetch
        get(`/cleanerlistings/${cleaner.listing.id}`)
          .then((r) => {
            setCleaner(r);
            setEditReviews(false);
          })
          .catch((e) => { });
      }
    } catch (e) {
      toast.error('could not add complaint');
    }
  };

  const uploadPicture = async () => {
    const formData = new FormData();
    formData.append('image', avatar);
    const token =
      (await firebase.getAuth().currentUser?.getIdToken(false)) ||
      localStorage.getItem(AUTH_HEADER) ||
      '';
    const resp = await post(
      `/cleanerlistings/${id}/image`,
      formData,
      token,
      false
    );
    if (resp.status !== 200) {
      toast.error('could not upload picture');
    } else {
      toast.success('uploaded picture');
      setAvatar(null);
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-100">
      <main className="py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={`https://homespritz-cleaner-avatars.s3.ca-central-1.amazonaws.com/${id}.jpg`}
                  alt="MISSING"
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            {cleaner && <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {cleaner?.name}
              </h1>
              {editingTier ? (
                <div>
                  ${cleaner?.listing?.price}
                  <DropDownBasic
                    label={''}
                    options={tierOptions}
                    selected={tierOptions ? tierOptions[0] : null}
                    onSelect={(val) => {
                      put(`/cleanerlistings/${cleaner.listing.id}/tier`, {
                        tier_id: val.id
                      })
                        .then((r) => {
                          if (r.status === 200) {
                            toast.success('updated tier');
                            setEditingTier(false);
                            get(`/cleanerlistings/${cleaner.listing.id}`)
                              .then((r) => {
                                setCleaner(r);
                              })
                              .catch((e) => {
                              });
                          } else {
                            toast.error('could not update tier');
                          }
                        })
                        .catch((e) => {
                          toast.error('could not update tier');
                        });
                    }}
                  />
                </div>
              ) : (
                <h2
                  className="text-1xl underline font-bold text-green-900 hover:text-green-700 cursor-pointer"
                  onClick={() => {
                    setEditingTier(true);
                  }}
                >
                  ${cleaner.listing?.price} (Tier:{' '}
                  {`${cleaner.listing?.tier?.name} (${cleaner.listing?.tier?.region?.name})`}
                  )
                </h2>
              )}

              <p className="text-sm font-medium text-gray-500">
                joined on <time dateTime="2020-08-25">{cleaner.joined}</time>
              </p>
              {cleaner.listing?.open ? (
                <h2 className="text-1xl font-bold text-green-600">
                  Open for bookings!
                </h2>
              ) : (
                <h2 className="text-1xl font-bold text-red-700">
                  {' '}
                  Closed to booking requests
                </h2>
              )}
              <div className="flex-shrink-0">
                <input
                  id="imguploader"
                  className="py-2"
                  type="file"
                  name="sampleFile"
                  onChange={(e) => setAvatar(e.target.files[0])}
                />
              </div>
              {avatar && (
                <button
                  onClick={uploadPicture}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Upload Image
                </button>
              )}
            </div>}
          </div>
          <div className="mt-6 flex flex-col justify-stretch space-y-4">
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              onClick={() =>
                dispatch({
                  type: 'OPEN_DRAWER',
                  drawerComponent: 'EDIT_CLEANER_FORM',
                  data: {
                    id: cleaner.listing.id,
                    price: cleaner.listing.price,
                    open: cleaner.listing.open,
                    listingTitle: cleaner.listing.displayName,
                    given_name: cleaner.given_name,
                    family_name: cleaner.family_name,
                    stripeAccount: cleaner.connectedAccount,
                    phoneNumber: cleaner.phoneNumber,
                    email: cleaner.email,
                    cleanerTier: cleaner.listing.tier,
                    cleanerTiers: tiers,
                    onSubmit: (data) => {
                      updateCleaner(data);
                    }
                  }
                })
              }
            >
              Edit Cleaner Listing
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              onClick={() => notifyCleanerAboutRequests()}
            >
              Poke Cleaner about requests
            </button>
          </div>
        </div>

        {cleaner && <div
          className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Listing id: {cleaner.listing?.id}
                  </h2>
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Listing display name: {cleaner.listing?.displayName}
                  </h2>
                  <h3
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Region: {cleaner.listing?.region}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    cleaner id: {cleaner.id}
                  </p>
                  <p
                    className="mt-2 flex items-center text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                      e.stopPropagation();
                      copyToClipboard(cleanerUrl, "Link");
                    }}
                    title="Click to copy link"
                  >
                    <LinkIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {cleanerUrl}
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        City Works in
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">{cleaner.listing?.region || 'missing'}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {cleaner.email}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-md font-semibold text-gray-500">
                        Booking stats
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <div className="space-y-2">
                          <p>
                            Completed:{" "}
                            <span className="font-semibold">
                              {cleanerStats?.bookingsCompleted || 0}
                            </span>
                          </p>
                          <p>
                            Rejected:{" "}
                            <span className="font-semibold">
                              {cleanerStats?.bookingsRejected || 0}
                            </span>
                          </p>
                          {/* <p>
                            Bookings cancelled:{" "}
                            <span className="font-semibold">
                              {cleanerStats?.bookingsCancelled || 0}
                            </span>
                          </p>
                          <p>
                            Offers accepted:{" "}
                            <span className="font-semibold">
                              {cleanerStats?.offersAccepted || 0}
                            </span>
                          </p> */}
                        </div>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {cleaner.phoneNumber}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        About
                        <button
                          onClick={() => setIsEditingDescription(!isEditingDescription)}
                          className="ml-2 inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-blue-700 hover:text-blue-900"
                        >
                          {isEditingDescription ? "Cancel" : "Edit"}
                        </button>
                      </dt>

                      {isEditingDescription ? (
                        <div className="mt-2">
                          <QuillEditor
                            initialDelta={cleaner.listing?.description_delta}
                            initialContent={cleaner.listing?.description}
                            onSave={(content) => handleSaveDescription(content)}
                          />
                        </div>
                      ) : (
                        <dd
                          className="mt-1 text-sm text-gray-900 ql-editor"
                          dangerouslySetInnerHTML={{ __html: cleaner.listing?.description_html || cleaner.listing?.description || "" }}
                        />
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        <div className="flex justify-between items-center">
                          <span>Tags</span>
                          <button
                            onClick={() => setIsTagSelectorOpen(true)}
                            className="text-sm text-lime-600 hover:text-lime-700"
                          >
                            Edit Tags
                          </button>
                        </div>
                      </dt>
                      <dd className="mt-1 flex flex-wrap gap-2">
                        {tags && tags.map((tag: CleanerTag) => (
                          <span
                            key={tag.id}
                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ${tag.client_visible
                              ? "bg-lime-100 text-lime-700"
                              : tag.cleaner_visible
                                ? "bg-yellow-100 text-yellow-700"
                                : "bg-orange-100 text-orange-700"
                              }`}
                          >
                            {tag.name}
                          </span>
                        ))}
                        {(!tags || tags.length === 0) && (
                          <span className="text-sm text-gray-500 italic">No tags assigned</span>
                        )}
                      </dd>
                    </div>

                    <div className="sm:col-span-2">
                      <p className="text-m font-medium text-gray-500 mb-2">
                        Bookings completed (
                        {cleaner.listing?.bookingsComplete || 0})
                      </p>
                      <p className="text-sm font-medium text-gray-500">
                        Reviews
                      </p>
                      {editReviews ? (
                        <div>
                          <EditCleanerReviewCard
                            reviewItems={cleaner.listing?.ratings}
                            onChange={(changed) => {
                              saveReviews(changed);
                            }}
                            onCancel={() => setEditReviews(!editReviews)}
                          />
                        </div>
                      ) : (
                        <>
                          <div className={'pb-6'}>
                            <CleanerReviewCard
                              reviewItems={[
                                {
                                  title: 'Quality',
                                  value: cleaner.listing?.ratings?.quality || 4
                                },
                                {
                                  title: 'Reliability',
                                  value:
                                    cleaner.listing?.ratings?.reliability || 4
                                },
                                {
                                  title: 'Communication',
                                  value:
                                    cleaner.listing?.ratings?.communication || 4
                                }
                              ]}
                            />
                          </div>
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
                            onClick={() => setEditReviews(!editReviews)}
                          >
                            Edit Reviews
                          </button>
                        </>
                      )}
                    </div>
                  </dl>
                </div>
                <div>
                  <div
                    onClick={() => {
                      history.push(`/cleaners/${cleaner.listing.id}/schedule`);
                    }}
                    className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                  >
                    Show Availability
                  </div>
                </div>
              </div>
            </section>

            {/* Comments*/}
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Map
                    </h2>
                  </div>
                  <div
                    className="px-4 py-6 sm:px-6"
                    style={{ marginBottom: '40px' }}
                  >
                    <CleanerMap
                      north={cleaner.listing?.coordinates.north}
                      south={cleaner.listing?.coordinates.south}
                      east={cleaner.listing?.coordinates.east}
                      west={cleaner.listing?.coordinates.west}
                      onSave={(newCoords) => {
                        updateCleanerMap(newCoords);
                      }}
                    />
                    <div className="mt-4">
                      <AreaMapPicker
                        listingId={cleaner?.listing?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Reviews listingId={cleaner?.listing?.id} />

            <section aria-labelledby="complaints-title">
              <div className="bg-white shadow sm:rounded-lg border-red-600 border sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-6 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-red-900"
                    >
                      Complaints & Writeups
                    </h2>
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={() =>
                        setAddComplaintButtonPressed(!addComplaintButtonPressed)
                      }
                    >
                      {!addComplaintButtonPressed
                        ? 'Add Complaint'
                        : 'Hide Complaint Form'}
                    </button>
                  </div>
                  {addComplaintButtonPressed && (
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex">
                        <div className="min-w-0 flex-1">
                          <form action="#">
                            <div>
                              <label htmlFor="comment" className="sr-only">
                                Complaint
                              </label>
                              <textarea
                                id="comment"
                                name="comment"
                                rows={3}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                placeholder="Add a complaint here..."
                                defaultValue={newComplaint.text}
                                value={newComplaint.text}
                                onChange={(e) => {
                                  setNewComplaint({
                                    ...newComplaint,
                                    text: e.target.value
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Type
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="text"
                                  id="clicomplaint-type"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="type of complaint"
                                  aria-describedby="complaint-type"
                                  value={newComplaint.type}
                                  onChange={(e) => {
                                    setNewComplaint({
                                      ...newComplaint,
                                      type: e.target.value
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <a
                                href="#"
                                className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                              >
                                <QuestionMarkCircleIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <span>Some HTML is okay.</span>
                              </a>
                              <button
                                type="submit"
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={addComplaint}
                              >
                                Save Complaint
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="px-4 sm:px-6">
                    <ul className="m-4 space-y-8">
                      {cleaner.listing?.complaints?.map((complaint) => (
                        <li key={complaint.id}>
                          <div className="flex space-x-3">
                            <div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>{complaint.text}</p>
                              </div>
                              <div className="mt-1 text-sm text-gray-700 flex flex-row">
                                <p className="font-semibold mr-2">Type: </p>
                                <p className="text-gray-600">
                                  {complaint.type}
                                </p>
                              </div>
                              <div className="mt-2 text-sm space-x-2 flex flex-row">
                                <p className="font-semibold">Created at:</p>
                                <span className="text-gray-600 font-medium">
                                  {complaint.created_at}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Notes
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6">
                    <ul className="space-y-8">
                      {comments.map((comment) => (
                        <li key={comment.id}>
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="text-sm">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900"
                                >
                                  {comment.name}
                                </a>
                              </div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>{comment.body}</p>
                              </div>
                              <div className="mt-2 text-sm space-x-2">
                                <span className="text-gray-500 font-medium">
                                  {comment.date}
                                </span>{' '}
                                <span className="text-gray-500 font-medium">
                                  &middot;
                                </span>{' '}
                                <button
                                  type="button"
                                  className="text-gray-900 font-medium"
                                >
                                  Reply
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <form action="#">
                        <div>
                          <label htmlFor="comment" className="sr-only">
                            About
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Add a note"
                            defaultValue={''}
                          />
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          <a
                            href="#"
                            className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                          >
                            <QuestionMarkCircleIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span>Some HTML is okay.</span>
                          </a>
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Comment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                Timeline
              </h2>

              {/* Activity Feed */}
              <div className="mt-6 flow-root">
                <ul className="-mb-8">
                  {bookings.map((booking, itemIdx) => (
                    <li key={booking.id}>
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div>{getStatus(booking.status)}</div>
                          <div
                            onClick={() =>
                              history.push(`/bookings/${booking.id}`)
                            }
                            className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4 hover:bg-gray-100 cursor-pointer"
                          >
                            <div className={''}>
                              <p className="text-sm text-grey-900">
                                With {booking.clientName}{' '}
                                {dayjs(booking.date).format('MMM-DD')}
                              </p>
                            </div>
                            <div className="text-right text-sm whitespace-nowrap text-gray-500">
                              <p>{booking.status}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6 flex flex-col justify-stretch">
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Load More
                </button>
              </div>
            </div>
          </section>
        </div>}
      </main>
      <TagSelector
        isOpen={isTagSelectorOpen}
        onClose={() => setIsTagSelectorOpen(false)}
        currentTags={cleaner?.listing?.tags?.map(tag => tag.id) || []}
        onSave={async (selectedTagIds) => {
          try {
            await post(`/tags/listings/${cleaner.listing.id}/tags`, { tagIds: selectedTagIds });
            // Refetch cleaner data to get updated tags
            const updatedCleaner = await get(`/cleanerlistings/${id}`);
            setCleaner(updatedCleaner);
            setIsTagSelectorOpen(false);
            toast.success("Tags updated successfully");
          } catch (error) {
            console.error("Error updating tags:", error);
            toast.error("Failed to update tags");
          }
        }}
      />
    </div>
  );
}
