import React, { useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

type Comment = {
  author?: {
    given_name: string;
    family_name: string;
  };
  content: string;
  date_created: string;
};

type CommentsProps = {
  comments: Comment[];
  onSubmit: (note: string) => void;
};

type CommentContentProps = {
  content: string;
  maxHeight?: number;
};

const CommentContent = ({ content, maxHeight = 200 }: CommentContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  React.useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > maxHeight);
    }
  }, [content, maxHeight]);

  return (
    <div className="mt-1 text-sm text-gray-700">
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-200 ${!isExpanded ? "max-h-[200px]" : ""
          }`}
      >
        <p>{content}</p>
      </div>
      {isOverflowing && (
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2 text-sm font-medium text-lime-600 hover:text-lime-700"
        >
          {isExpanded ? "See less" : "See more"}
        </button>
      )}
    </div>
  );
};

const Comments = ({ comments, onSubmit }: CommentsProps) => {
  const [newNote, setNewNote] = useState('');
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);
  const { register, handleSubmit } = useForm();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  React.useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(containerRef.current.scrollHeight > 400);
    }
  }, [comments]);

  const handleNoteSubmit = () => {
    onSubmit(newNote);
    setNewNote('');
    setShowNoteForm(false);
  };

  return (
    <>
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                Notes
              </h2>
            </div>
            <div
              ref={containerRef}
              className={`px-4 py-6 sm:px-6 relative ${!showAllNotes ? "max-h-[200px] overflow-hidden" : ""
                }`}
            >
              <ul className="space-y-8">
                {comments.map((comment, index) => (
                  <li key={index}>
                    <div className="flex space-x-3">
                      <div className="flex-1">
                        <div className="text-sm">
                          <a href="#" className="font-medium text-gray-900">
                            {`${comment.author?.given_name || ""} ${comment.author?.family_name || ""
                              }`.trim()}
                          </a>
                        </div>
                        <CommentContent content={comment.content} />
                        <div className="mt-2 text-sm space-x-2">
                          <span className="text-gray-500 font-medium">
                            {comment.date_created}
                          </span>{' '}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {isOverflowing && !showAllNotes && (
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent" />
              )}
            </div>
            {isOverflowing && (
              <div className="px-4 py-3 bg-gray-50 text-center">
                <button
                  type="button"
                  onClick={() => setShowAllNotes(!showAllNotes)}
                  className="text-sm font-medium text-lime-600 hover:text-lime-700"
                >
                  {showAllNotes ? "Show less notes" : "Show all notes"}
                </button>
              </div>
            )}
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="min-w-0 flex-1">
                {!showNoteForm ? (
                  <button
                    type="button"
                    onClick={() => setShowNoteForm(true)}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                  >
                    Add Note
                  </button>
                ) : (
                  <form onSubmit={handleSubmit(handleNoteSubmit)}>
                    <div>
                      <label htmlFor="comment" className="sr-only">
                        Add a note
                      </label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={3}
                        className="shadow-sm block w-full focus:ring-lime-600 focus:border-lime-600 sm:text-sm border border-gray-300 rounded-md"
                        placeholder="Add a note"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                      />
                    </div>
                    <div className="mt-3 flex items-center justify-end space-x-2">
                      <button
                        type="button"
                        onClick={() => setShowNoteForm(false)}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                      >
                        Comment
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Comments;
