import React, { useState, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { get, post } from "../../helper/fetch";
import { RadioGroup } from "@headlessui/react";
import ExtrasPicker3 from "../../components/ExtrasPicker3";
import dayjs from "dayjs";
import { recurrences } from "../../settings/constants";
import CouponSearchForm from "../../components/CouponSearchForm/SearchForm";
/**
 * Type definitions for the booking form
 */
type Property = {
  id: number;
  address: string;
  squareFootage: number;
  numberOfRooms: number;
  numberOfBathrooms: number;
  province: string;
};

type Client = {
  id: string;
  name: string;
  properties: Property[];
};

type Cleaner = {
  email: string;
  firstName: string;
  id: string;
  joined: string;
  lastName: string;
  listingId: number;
  name: string;
  phoneNumber: string;
  price: number;
  tierId: number;
  tierMaxPrice: number;
  tierMinPrice: number;
  tierName: string;
};

type Extra = {
  id: number;
  name: string;
  cost: number;
};

type FormData = {
  client: Client | null;
  cleaner: Cleaner | null;
  property: Property | null;
  bookingDate: Date;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  extras: Set<number>;
  pricePerHour: number;
  tierLimit: number;
  couponCode: string | null;
  recurrence: string;
};

/**
 * Time selection constants
 */
const HOURS = Array.from({ length: 17 }, (_, i) => ({
  value: i + 6,
  label: `${i + 6}:00`,
  id: i + 6
})); // 6 AM to 22 PM

const MINUTES = [
  { value: 0, label: "00" },
  // { value: 15, label: "15" },
  { value: 30, label: "30" },
  // { value: 45, label: "45" }
];

/**
 * Custom DatePicker wrapper with Tailwind styling
 */
const StyledDatePicker: React.FC<{
  selected: Date;
  onChange: (date: Date) => void;
}> = ({ selected, onChange }) => (
  <DatePicker
    selected={selected}
    onChange={onChange}
    className="w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
    wrapperClassName="w-full"
    dateFormat="MMMM d, yyyy"
  />
);

/**
 * Searchable select component using HeadlessUI Combobox
 */
const SearchableSelect = <T extends { id: string | number; name?: string; address?: string }>({
  options,
  value,
  onChange,
  onSearch,
  placeholder,
  displayKey = "name"
}: {
  options: T[];
  value: T | null;
  onChange: (value: T) => void;
  onSearch?: (query: string) => void;
  placeholder: string;
  displayKey?: "name" | "address";
}) => {
  const [query, setQuery] = useState("");

  const handleSearch = (searchQuery: string) => {
    setQuery(searchQuery);
    onSearch?.(searchQuery);
  };

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-lime-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(item: T) => item ? item[displayKey] || "" : ""}
            onChange={(event) => handleSearch(event.target.value)}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
            {options.length === 0 ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              options.map((item) => (
                <Combobox.Option
                  key={item.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-lime-600 text-white" : "text-gray-900"
                    }`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                        {item[displayKey]}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-lime-600"
                            }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

/**
 * Time select component
 */
const TimeSelect: React.FC<{
  options: { value: number; label: string }[];
  value: number;
  onChange: (value: number) => void;
  label: string;
}> = ({ options, value, onChange, label }) => (
  <Combobox value={value} onChange={onChange}>
    <div className="relative mt-1">
      <Combobox.Input
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
        displayValue={(val: number) => options.find(opt => opt.value === val)?.label || ""}
        onChange={() => { }}
      />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </Combobox.Button>
      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
        {options.map((option) => (
          <Combobox.Option
            key={option.value}
            value={option.value}
            className={({ active }) =>
              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-lime-600 text-white" : "text-gray-900"
              }`
            }
          >
            {({ selected, active }) => (
              <>
                <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                  {option.label}
                </span>
                {selected ? (
                  <span
                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-lime-600"
                      }`}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </div>
  </Combobox>
);

/**
 * Property list component
 */
const PropertyList: React.FC<{
  properties: Property[];
  selected: Property | null;
  onChange: (property: Property) => void;
}> = ({ properties, selected, onChange }) => {
  return (
    <RadioGroup value={selected} onChange={onChange}>
      <div className="space-y-2">
        {properties.map((property) => (
          <RadioGroup.Option
            key={property.id}
            value={property}
            className={({ active, checked }) =>
              `${active ? "ring-2 ring-lime-500 ring-offset-2" : ""}
              ${checked ? "" : "bg-white hover:bg-gray-50"}
              relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
            }
          >
            {({ checked }) => (
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className={`font-medium ${checked ? "" : "text-gray-900"}`}
                    >
                      {property.address}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={`inline ${checked ? "" : "text-gray-500"}`}
                    >
                      <span>
                        {property.numberOfRooms} rooms • {property.numberOfBathrooms} bathrooms • {property.squareFootage} sq ft
                      </span>
                    </RadioGroup.Description>
                  </div>
                </div>
                {checked && (
                  <div className="shrink-0 text-lime-600">
                    <CheckIcon className="h-6 w-6" />
                  </div>
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

/**
 * NewBookingForm component for creating new bookings
 */
type NewBookingFormProps = {
  onSuccess?: () => void;
  selectedClientId?: string;
};

const NewBookingForm: React.FC<NewBookingFormProps> = ({
  onSuccess,
  selectedClientId = "77zXGG1TLoPS8y3QfvvEmQ" // Default value
}) => {
  const { handleSubmit, formState: { errors } } = useForm<FormData>();

  const submitNewBooking = async (data: FormData) => {
    const newBooking = {
      clientId: data.client?.id,
      cleanerId: data.cleaner?.id,
      propertyId: data.property?.id,
      bookingDate: dayjs(data.bookingDate).format('YYYY-MM-DD'),
      startHour: data.startHour,
      startMinute: data.startMinute,
      endHour: data.endHour,
      endMinute: data.endMinute,
      extras: Array.from(data.extras),
      couponCode: null,
      other_info: null,
      recurrence: data.recurrence,
      card: null,
      receipt: receipt
    };

    try {
      post('/bookings/', newBooking)
        .then((res) => {
          if (res.status !== 201) {
            res.text().then((r) => {
              toast.error(JSON.stringify(r));
            });
            throw Error('could not create booking');
          }
          toast.success('Booking created');
          return res.json();
        })
        .then((r) => {
          if (!r.id) {
          } else {
            window.open(`/bookings/${r.id}`);
          }
        })
        .catch((e) => {
        });
    } catch (e) {
      console.log(
        `Cannot submit new booking request. ${JSON.stringify(e.errors)}`
      );
      toast.error(
        `Cannot submit new booking request. ${JSON.stringify(e.errors)}`
      );
    }
  };

  // Form state
  const [formData, setFormData] = useState<FormData>({
    client: null,
    cleaner: null,
    property: null,
    bookingDate: new Date(),
    startHour: 9,
    startMinute: 0,
    endHour: 11,
    endMinute: 0,
    extras: new Set(),
    pricePerHour: 0,
    tierLimit: 0,
    couponCode: null,
    recurrence: recurrences[0],
  });

  // Search results state
  const [clientResults, setClientResults] = useState<Client[]>([]);
  const [cleanerResults, setCleanerResults] = useState<Cleaner[]>([]);
  const [availableExtras, setAvailableExtras] = useState<Extra[]>([]);
  const [receipt, setReceipt] = useState<{
    hours: number;
    extras: number;
    subtotal: number;
    tax: number;
    cleaner_total: number;
    fee: number;
    total: number;
    coupon_covers: number;
    hourly_rate: number;
  }>(null);
  const [isManualReceiptMode, setIsManualReceiptMode] = useState<boolean>(false);

  /**
   * Handles client search
   */
  const searchClients = async (searchTerm: string): Promise<void> => {
    try {
      const response = await get(`/clients?${new URLSearchParams({
        name: searchTerm,
        size: "15"
      })}`);

      if (!response.clientsParsed) {
        throw new Error("Invalid response format");
      }

      setClientResults(response.clientsParsed);
    } catch (error) {
      toast.error("Failed to search clients");
      console.error("Client search error:", error);
    }
  };

  /**
   * Handles cleaner search
   */
  const searchCleaners = async (searchTerm: string): Promise<void> => {
    try {
      const response = await get(`/cleanerlistings?${new URLSearchParams({
        name: searchTerm,
        size: "15"
      })}`);

      if (!response.cleanersParsed) {
        throw new Error("Invalid response format");
      }

      setCleanerResults(response.cleanersParsed);
    } catch (error) {
      toast.error("Failed to search cleaners");
      console.error("Cleaner search error:", error);
    }
  };

  /**
   * Handles client selection and loads their properties
   */
  const handleClientSelect = async (selectedClient: Client): Promise<void> => {
    try {
      const properties = await get(`/clients/${selectedClient.id}/properties`);

      setFormData({
        ...formData,
        client: {
          ...selectedClient,
          properties
        },
        property: null
      });
    } catch (error) {
      toast.error("Failed to load client properties");
      console.error("Error loading properties:", error);
    }
  };

  /**
   * Handles form submission
   */
  const onSubmit = async (data: FormData): Promise<void> => {
    if (!formData.client || !formData.cleaner || !formData.property) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      const bookingData = {
        clientId: formData.client.id,
        cleanerId: formData.cleaner.id,
        propertyId: formData.property.id,
        bookingDate: formData.bookingDate.toISOString().split("T")[0],
        startHour: formData.startHour,
        startMinute: formData.startMinute,
        endHour: formData.endHour,
        endMinute: formData.endMinute,
        extras: Array.from(formData.extras)
      };

      const response = await post("/bookings/", bookingData);

      if (response.status === 201) {
        const result = await response.json();
        toast.success("Booking created successfully");
        if (result.id) {
          window.open(`/bookings/${result.id}`);
        }
        onSuccess?.();
      } else {
        throw new Error("Failed to create booking");
      }
    } catch (error) {
      toast.error("Failed to create booking");
      console.error("Booking creation error:", error);
    }
  };

  const calculateReceipt = async (hours: number, pricePerHour: number, tierLimit: number, extras: number[], couponCode: string | null, property: Property | null, manualMode: boolean) => {
    let extraIds = [];
    if (manualMode) {
      return;
    }
    if (extras.length > 0) {
      extraIds = extras;
    }
    post('/bookings/receipt/new', {
      hours: hours,
      extras: extraIds,
      pricePerHour: pricePerHour,
      tierLimit: tierLimit,
      provinceIdentifier: property?.province || 'AB',
      coupon: couponCode
    })
      .then((res) => {
        if (res.status !== 200) throw new Error('Could not calc reciept');
        return res.json();
      })
      .then((r) => {
        console.log(r);
        setReceipt(r);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const calculateHours = (startHour: number, startMinute: number, endHour: number, endMinute: number) => {
    let from = startHour;
    if (startMinute > 0) from += 0.5;
    let to = endHour;
    if (endMinute > 0) to += 0.5;
    return to - from;
  }

  // Add useEffect to load and set the client when component mounts
  useEffect(() => {
    const loadSelectedClient = async () => {
      if (selectedClientId) {
        try {
          // Fetch client details
          const response = await get(`/clients/${selectedClientId}`);

          // Fetch client properties
          const properties = await get(`/clients/${selectedClientId}/properties`);

          // Set the client in form data with their properties
          setFormData(prev => ({
            ...prev,
            client: {
              ...response,
              properties
            }
          }));

        } catch (error) {
          console.error("Error loading client:", error);
          toast.error("Failed to load client details");
        }
      }
    };

    loadSelectedClient();
  }, [selectedClientId]);

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {/* Client Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Client
          </label>
          <SearchableSelect
            options={clientResults}
            value={formData.client}
            onChange={handleClientSelect}
            onSearch={searchClients}
            placeholder="Search clients..."
          />
        </div>

        {/* Property Selection */}
        {formData.client && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Property
            </label>
            <PropertyList
              properties={formData.client.properties}
              selected={formData.property}
              onChange={(property) => setFormData({
                ...formData,
                property
              })}
            />
          </div>
        )}

        {/* Cleaner Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cleaner
          </label>
          <SearchableSelect
            options={cleanerResults}
            // @ts-ignore
            value={{
              id: formData.cleaner?.id,
              listingId: formData.cleaner?.listingId,
              name: formData.cleaner?.name,
              price: formData.cleaner?.price,
              tierId: formData.cleaner?.tierId,
              tierName: formData.cleaner?.tierName
            }}
            onChange={(cleaner) => {
              setFormData({
                ...formData,
                cleaner,
                pricePerHour: cleaner.price
              });
              const hours = calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute);
              calculateReceipt(hours, cleaner.price, cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
            }}
            onSearch={searchCleaners}
            placeholder="Search cleaners..."
          />
          <div className="mt-2">
            {formData.cleaner && (
              <div className="flex flex-col items-start text-sm">
                <div className="flex items-center">
                  <div className="flex flex-row items-center">
                    <span className="text-gray-600">
                      Tier: {formData.cleaner.tierName || "Not Set"},
                    </span>
                    <div className="flex flex-row items-center mx-2">
                      <span className="text-gray-600">
                        Max Price: ${formData.cleaner.tierMaxPrice || 100}
                      </span>
                      <input
                        type="text"
                        value={formData.cleaner.tierMaxPrice || 100}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            cleaner: {
                              ...formData.cleaner,
                              tierMaxPrice: parseFloat(e.target.value)
                            }
                          });
                          const hours = calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute);
                          calculateReceipt(hours, formData.pricePerHour, parseFloat(e.target.value), Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                        }}
                        className="w-12 px-2 py-1 ml-2 mr-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      /> / hr
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <span className="text-gray-600">Price per hour:</span>
                    <div className="flex flex-row items-center">
                      <input
                        type="number"
                        value={formData.pricePerHour}
                        onChange={(e) => {
                          const newPrice = parseFloat(e.target.value);
                          setFormData({
                            ...formData,
                            pricePerHour: newPrice
                          });
                          const hours = calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute);
                          calculateReceipt(hours, newPrice, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                        }}
                        className="w-16 px-2 py-1 ml-2 mr-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      /> / hr
                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>

        {/* Date Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <StyledDatePicker
            selected={formData.bookingDate}
            onChange={(date) => setFormData({
              ...formData,
              bookingDate: date
            })}
          />
        </div>

        {/* Time Selection */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Time
            </label>
            <div className="grid grid-cols-2 gap-2">
              <TimeSelect
                options={HOURS}
                value={formData.startHour}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    startHour: value
                  });
                  const startTime = value + formData.startMinute / 60;
                  const endTime = formData.endHour + formData.endMinute / 60;
                  const hours = endTime - startTime;
                  if (hours > 0) {
                    calculateReceipt(hours, formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                  }
                }}
                label="Hour"
              />
              <TimeSelect
                options={MINUTES}
                value={formData.startMinute}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    startMinute: value
                  });
                  const startTime = formData.startHour + value / 60;
                  const endTime = formData.endHour + formData.endMinute / 60;
                  const hours = endTime - startTime;
                  if (hours > 0) {
                    calculateReceipt(hours, formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                  }
                }}
                label="Minute"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Time
            </label>
            <div className="grid grid-cols-2 gap-2">
              <TimeSelect
                options={HOURS}
                value={formData.endHour}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    endHour: value
                  });
                  const startTime = formData.startHour + formData.startMinute / 60;
                  const endTime = value + formData.endMinute / 60;
                  const hours = endTime - startTime;
                  if (hours > 0) {
                    calculateReceipt(hours, formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                  }
                }}
                label="Hour"
              />
              <TimeSelect
                options={MINUTES}
                value={formData.endMinute}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    endMinute: value
                  });
                  const startTime = formData.startHour + formData.startMinute / 60;
                  const endTime = formData.endHour + value / 60;
                  const hours = endTime - startTime;
                  if (hours > 0) {
                    calculateReceipt(hours, formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, isManualReceiptMode);
                  }
                }}
                label="Minute"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Extras
          </label>
          <ExtrasPicker3
            onSelectionChange={(extras) => {
              setFormData({
                ...formData,
                extras: new Set(extras.map(extra => extra.id))
              });
              const hours = calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute);
              calculateReceipt(hours, formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(extras.map(extra => extra.id)), formData.couponCode, formData.property, isManualReceiptMode);
            }}
          />
        </div>

        <div>
          <label
            htmlFor="recurrence"
            className="block text-sm font-medium text-gray-700"
          >
            Recurrence
          </label>
          <select
            id="recurrence"
            name="recurrence"
            value={formData.recurrence}
            onChange={(e) => setFormData({
              ...formData,
              recurrence: e.target.value
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            {recurrences.map((recurrence) => (
              <option key={recurrence} value={recurrence}>
                {recurrence.charAt(0).toUpperCase() + recurrence.slice(1)}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Coupon Code
          </label>
          <input
            type="text"
            value={formData.couponCode}
            onChange={(e) => {
              setFormData({
                ...formData,
                couponCode: e.target.value
              })
              if (e.target.value.length > 2) {
                calculateReceipt(calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute), formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), e.target.value, formData.property, isManualReceiptMode);
              }
            }}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
          />
        </div>

        <ul role="list" className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
          <li key={'combined'} className="py-6">
            <div className="">
              <div className='flex flex-row justify-between items-center'>
                <h3>Receipt</h3>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-600">Manual Mode</span>
                  <button
                    type="button"
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 ${isManualReceiptMode ? 'bg-lime-600' : 'bg-gray-200'
                      }`}
                    role="switch"
                    aria-checked={isManualReceiptMode}
                    onClick={() => {
                      if (isManualReceiptMode) {
                        calculateReceipt(calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute), formData.pricePerHour, formData.cleaner.tierMaxPrice, Array.from(formData.extras), formData.couponCode, formData.property, !isManualReceiptMode);
                      }
                      setIsManualReceiptMode(!isManualReceiptMode);
                    }}
                  >
                    <span
                      aria-hidden="true"
                      className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${isManualReceiptMode ? 'translate-x-5' : 'translate-x-0'
                        }`}
                    />
                  </button>
                </div>
              </div>
              <p className="text-gray-500">
                Hours: {calculateHours(formData.startHour, formData.startMinute, formData.endHour, formData.endMinute)}
              </p>
            </div>
            {receipt && (
              <dl className="space-y-6 pt-6 text-sm font-medium text-gray-900">
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Hourly Rate</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.hourly_rate}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          hourly_rate: parseFloat(e.target.value),
                          total: parseFloat(e.target.value) + receipt.fee + receipt.tax - receipt.coupon_covers
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd>${receipt.hourly_rate}</dd>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Subtotal</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.subtotal}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          subtotal: parseFloat(e.target.value),
                          total: parseFloat(e.target.value) + receipt.fee + receipt.tax - receipt.coupon_covers
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd>${receipt.subtotal}</dd>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Fee <span className="text-gray-500">(subtotal * 43%)</span></dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.fee}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          fee: parseFloat(e.target.value),
                          total: receipt.subtotal + parseFloat(e.target.value) + receipt.tax - receipt.coupon_covers
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd>${receipt.fee}</dd>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Taxes</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.tax}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          tax: parseFloat(e.target.value),
                          total: receipt.subtotal + receipt.fee + parseFloat(e.target.value) - receipt.coupon_covers
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd>${receipt.tax}</dd>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Coupon Covers</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.coupon_covers}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          coupon_covers: parseFloat(e.target.value),
                          total: receipt.subtotal + receipt.fee + receipt.tax - parseFloat(e.target.value)
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd>${receipt.coupon_covers}</dd>
                  )}
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Total (client pays)</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.total}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          total: parseFloat(e.target.value)
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd className="text-base">${receipt.total}</dd>
                  )}
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Cleaner gets</dt>
                  {isManualReceiptMode ? (
                    <input
                      type="number"
                      value={receipt.cleaner_total}
                      onChange={(e) => {
                        setReceipt({
                          ...receipt,
                          cleaner_total: parseFloat(e.target.value)
                        });
                      }}
                      className="w-24 px-2 py-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  ) : (
                    <dd className="text-base">${receipt.cleaner_total}</dd>
                  )}
                </div>
              </dl>
            )}
          </li>
        </ul>

        <button
          type="button"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
          onClick={() => submitNewBooking(formData)}
        >
          Create Booking
        </button>
      </div>
    </div>
  );
};

export default NewBookingForm; 