import React, { useState, useEffect } from 'react';
import AnnouncementList from './AnnouncementList';
import { del, get, post, put } from '../../helper/fetch';
import AnnouncementForm from './AnnouncementForm';

export interface Announcement {
  id: number
  title: string
  content_html: string
  content_delta: string
  created_at: string
  updated_at: string
}

export interface AnnouncementWithAuthor extends Announcement {
  author: {
    id: string;
    name: string;
  }
}

const AnnouncementManager: React.FC = () => {
  const [announcements, setAnnouncements] = useState<AnnouncementWithAuthor[]>([])
  const [editingAnnouncement, setEditingAnnouncement] = useState<Announcement | null>(null)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [error, setError] = useState<string | null>(null);


  function getAnnouncements() {
    get('/settings/announcements')
      .then((resp) => {
        if (resp) {
          setAnnouncements(resp.announcements);
        } else {
          setError(resp.error);
        }
      });
  }

  useEffect(() => {
    getAnnouncements();
  }, [])

  const handleCreate = (newAnnouncement: Omit<Announcement, 'id' | 'created_at' | 'updated_at'>) => {
    const announcement = {
      ...newAnnouncement,
    }
    post('/settings/announcements', announcement)
      .then(() => {
        getAnnouncements();
      })
    setIsFormVisible(false)
  }

  const handleEdit = async (updatedAnnouncement: Announcement) => {
    await put(`/settings/announcements/${updatedAnnouncement.id}`, {
      title: updatedAnnouncement.title,
      content_html: updatedAnnouncement.content_html,
      content_delta: updatedAnnouncement.content_delta,
    });
    getAnnouncements();
  }

  const handleDelete = async (id: number) => {
    await del(`/settings/announcements/${id}`);
    getAnnouncements();
  }

  return (
    <div className="space-y-4">
      <button
        onClick={() => setIsFormVisible(true)}
        className="bg-lime-600 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded"
      >
        Create New Announcement
      </button>
      {isFormVisible && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <AnnouncementForm
            onSubmit={handleCreate}
            onCancel={() => setIsFormVisible(false)}
          />
        </div>
      )}
      {error && <div className="text-red-500">{error}</div>}
      {announcements.length > 0 && <AnnouncementList
        announcements={announcements}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      }
    </div>
  )
}

export default AnnouncementManager;