import React from "react";

type LoadingSpinnerProps = {
  size?: "small" | "medium" | "large";
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = "medium" }) => {
  const sizeClasses = {
    small: "h-4 w-4",
    medium: "h-8 w-8",
    large: "h-12 w-12"
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className={`${sizeClasses[size]} animate-spin rounded-full border-2 border-gray-300 border-t-lime-600`}
      />
    </div>
  );
};

export default LoadingSpinner; 