import React, { useEffect, useState } from 'react';
import { QuillEditor } from '../../components/QuillEditor';
import { Menu } from '@headlessui/react';
import { FlagIcon } from '@heroicons/react/outline';
import { CodeIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Announcement, AnnouncementWithAuthor } from './AnnouncementManager';
import dayjs from 'dayjs';

/**
 * Capitalizes the first letter of each word in a name
 * @param name - The name to capitalize
 * @returns The capitalized name
 */
const capitalizeNames = (name: string): string => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export default function IndividualCard({ announcement, onEdit, onDelete }: { announcement: AnnouncementWithAuthor, onEdit: (announcement: Announcement) => void, onDelete: (id: number) => void }) {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [newContent, setNewContent] = useState({ content_html: announcement.content_html, content_delta: announcement.content_delta });
  const [isSaving, setIsSaving] = useState(false);

  return (
    <div>
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow" key={announcement.id}>
        <div key={announcement.id} className="bg-white px-4 py-5 sm:px-6">
          <div className="flex space-x-3">
            <div className="flex-1 flex-col">
              <div className="min-w-0 flex-1">
                <p className="text-lg font-semibold text-gray-900">
                  {announcement.title}
                </p>
                <div className="shrink-0">
                  <p className="text-sm text-gray-500">
                    {capitalizeNames(announcement.author.name)}, {dayjs(announcement.created_at).format('MMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex shrink-0 self-center">
              <Menu as="div" className="inline-block text-left">
                <div>
                  <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon aria-hidden="true" className="h-5 w-5" />
                  </Menu.Button>
                </div>

                <Menu.Items
                  className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setIsEditingDescription(true)}
                          className="flex w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                          <CodeIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400" />
                          <span>Edit</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => onDelete(announcement.id)}
                          className="flex w-full px-4 py-2 text-sm text-red-600 data-[focus]:bg-gray-100 data-[focus]:text-red-700 data-[focus]:outline-none"
                        >
                          <FlagIcon aria-hidden="true" className="mr-3 h-5 w-5 text-red-400" />
                          <span>Delete</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </div>

        {isEditingDescription ? (
          <div className="mt-2 px-4 pb-5 sm:p-6 sm:pt-0">
            <QuillEditor
              initialDelta={announcement.content_delta}
              onChange={(data) => {
                setNewContent({ content_delta: data.delta, content_html: data.html });
              }}
            />
            <div className="mt-2 flex justify-end space-x-3">
              <button
                onClick={() => {
                  setIsEditingDescription(false);
                  // Reset content to original announcement content
                  setNewContent({
                    content_html: announcement.content_html,
                    content_delta: announcement.content_delta
                  });
                }}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  setIsSaving(true);
                  try {
                    await onEdit({ ...announcement, ...newContent });
                    setIsEditingDescription(false);
                  } finally {
                    setIsSaving(false);
                  }
                }}
                disabled={isSaving}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Saving...
                  </>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </div>
        ) : (
          <dd
            className="m-4 mt-1 text-sm text-gray-900 ql-editor"
            dangerouslySetInnerHTML={{ __html: newContent.content_html || "" }}
          />
        )}
      </div>
    </div>
  );
}
