import React, { useState } from 'react';
import { Tag } from './TagsManager';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Menu } from '@headlessui/react';
import { CodeIcon } from '@heroicons/react/outline';
import { FlagIcon } from '@heroicons/react/outline';
import { CheckIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/solid";
import { TagForm } from './TagForm';

type TagCardProps = {
  tag: Tag;
  onEdit: (tag: Tag) => void;
  onDelete: (id: number) => void;
}

const TagCard: React.FC<TagCardProps> = ({ tag, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow p-4">
        <TagForm
          tag={tag}
          onSubmit={(data) => {
            onEdit(data as Tag);
            setIsEditing(false);
          }}
          onCancel={() => setIsEditing(false)}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow" key={tag.id}>
        <div key={tag.id} className="bg-white px-4 py-5 sm:px-6">
          <div className="flex space-x-3">
            <div className="flex-1">
              <div className="mb-4">
                <div className="flex items-center justify-between">
                  <p className="text-lg font-semibold text-gray-900">{tag.name}</p>
                  <span
                    className={`inline-flex items-center rounded-full px-4 py-1.5 text-base font-medium ${tag.is_active
                      ? "bg-green-100 text-green-800"
                      : "bg-gray-100 text-gray-800"
                      }`}
                  >
                    {tag.is_active ? "Active" : "Inactive"}
                  </span>
                </div>
                <p className="text-sm text-gray-500">{tag.description}</p>
              </div>

              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  {tag.client_visible ? (
                    <CheckIcon className="h-4 w-4 text-green-500" />
                  ) : (
                    <XIcon className="h-4 w-4 text-red-500" />
                  )}
                  <span className="text-sm text-gray-600">Client Visible</span>
                </div>
                <div className="flex items-center space-x-2">
                  {tag.cleaner_visible ? (
                    <CheckIcon className="h-4 w-4 text-green-500" />
                  ) : (
                    <XIcon className="h-4 w-4 text-red-500" />
                  )}
                  <span className="text-sm text-gray-600">Cleaner Visible</span>
                </div>
                <div className="flex items-center space-x-2">
                  {tag.admin_visible ? (
                    <CheckIcon className="h-4 w-4 text-green-500" />
                  ) : (
                    <XIcon className="h-4 w-4 text-red-500" />
                  )}
                  <span className="text-sm text-gray-600">Admin Visible</span>
                </div>
              </div>
            </div>

            <div className="flex shrink-0 self-center">
              <Menu as="div" className="inline-block text-left">
                <div>
                  <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon aria-hidden="true" className="h-5 w-5" />
                  </Menu.Button>
                </div>

                <Menu.Items
                  className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setIsEditing(true)}
                          className="flex w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                          <CodeIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400" />
                          <span>Edit</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => onDelete(tag.id)}
                          className="flex w-full px-4 py-2 text-sm text-red-600 data-[focus]:bg-gray-100 data-[focus]:text-red-700 data-[focus]:outline-none"
                        >
                          <FlagIcon aria-hidden="true" className="mr-3 h-5 w-5 text-red-400" />
                          <span>Delete</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagCard;
