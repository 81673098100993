

import React, { useState, useEffect } from 'react';
import { del, get, post, put } from '../../helper/fetch';
import TagList from './TagList';
import { TagForm } from './TagForm';

export type Tag = {
  id?: number;
  category_id: number;
  name: string;
  description: string | null;
  client_visible: boolean;
  cleaner_visible: boolean;
  admin_visible: boolean;
  is_active: boolean
}

const TagsManager: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([])
  const [editingTag, setEditingTag] = useState<Tag | null>(null)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [error, setError] = useState<string | null>(null);


  function getTags() {
    get('/tags')
      .then((resp) => {
        if (resp.data) {
          setTags(resp.data);
        } else {
          setError(resp.message);
        }
      });
  }

  useEffect(() => {
    getTags();
  }, [])

  const handleCreate = (newTag: Omit<Tag, 'id'>) => {
    const tag = {
      ...newTag,
    }
    post('/tags', tag)
      .then(() => {
        getTags();
      })
    setIsFormVisible(false)
  }

  const handleEdit = async (updatedTag: Tag) => {
    await put(`/tags/${updatedTag.id}`, {
      name: updatedTag.name,
      description: updatedTag.description,
      client_visible: updatedTag.client_visible,
      cleaner_visible: updatedTag.cleaner_visible,
      admin_visible: updatedTag.admin_visible,
      is_active: updatedTag.is_active,
    });
    getTags();
  }

  const handleDelete = async (id: number) => {
    await del(`/tags/${id}`);
    getTags();
  }

  return (
    <div className="space-y-4">
      <button
        onClick={() => setIsFormVisible(true)}
        className="bg-lime-600 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded"
      >
        Create New Tag
      </button>
      {isFormVisible && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <TagForm
            onSubmit={handleCreate}
            onCancel={() => setIsFormVisible(false)}
          />
        </div>
      )}
      {error && <div className="text-red-500">{error}</div>}
      {tags.length > 0 && <TagList
        tags={tags}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      }
    </div>
  )
}

export default TagsManager;
