import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

type TagFormValues = {
  name: string;
  client_visible: boolean;
  cleaner_visible: boolean;
  admin_visible: boolean;
  is_active: boolean;
}

interface TagFormProps {
  tag?: TagFormValues
  onSubmit: (data: TagFormValues) => void;
  onCancel: () => void;
}

export function TagForm({ tag, onSubmit, onCancel }: TagFormProps) {
  const [isSaving, setIsSaving] = useState(false)

  const form = useForm<TagFormValues>({
    // resolver: zodResolver(tagSchema),
    defaultValues: tag || {
      name: '',
      client_visible: false,
      cleaner_visible: false,
      admin_visible: true,
      is_active: true,
    },
  })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          {...form.register('name')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
          placeholder="Enter tag name"
        />
        {form.formState.errors.name && (
          <p className="mt-2 text-sm text-red-600">{form.formState.errors.name.message}</p>
        )}
        <p className="mt-2 text-sm text-gray-500">
          The name of the tag as it will appear in the system.
        </p>
      </div>

      <div className="space-y-4">
        {['client_visible', 'cleaner_visible', 'admin_visible', 'is_active'].map((field) => (
          <div key={field} className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={field}
                type="checkbox"
                {...form.register(field as keyof TagFormValues)}
                className="focus:ring-lime-500 h-4 w-4 text-lime-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={field} className="font-medium text-gray-700">
                {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </label>
              <p className="text-gray-500">
                {field === 'is_active'
                  ? 'If unchecked, this tag will be considered inactive.'
                  : `If checked, this tag will be visible to ${field.split('_')[0]}s.`}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="flex gap-4">
          <button
            type="submit"
            disabled={isSaving}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSaving ? "Saving..." : tag ? "Update Tag" : "Create Tag"}
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  )
}

