import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid';

const getPages = (currentPage: number, numberOfPages: number, onPageSelect: (page: number) => void) => {
  const selectedBorder =
    'cursor-pointer border-lime-600 text-gray-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
  return (
    <div className="hidden md:-mt-px md:flex">
      <div className={selectedBorder}>
        {currentPage + 1} / {numberOfPages}
      </div>
    </div>
  );
};

type PagerProps = {
  currentPage: number;
  numberOfPages: number;
  onPageSelect: (page: number) => void;
  onNext: () => void;
  onPrev: () => void;
};

export default function Pager({
  currentPage,
  numberOfPages,
  onPageSelect,
  onNext,
  onPrev
}: PagerProps) {
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage >= numberOfPages - 1;

  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex pl-4">
        <button
          onClick={onPrev}
          disabled={isFirstPage}
          className={`border-t-2 border-transparent pt-4 pb-5 pr-1 inline-flex items-center text-sm font-medium ${isFirstPage
            ? "text-gray-300 cursor-not-allowed"
            : "text-gray-500 hover:text-gray-700 hover:border-lime-600 cursor-pointer"
            }`}
        >
          <ArrowNarrowLeftIcon
            className={`mr-3 h-5 w-5 ${isFirstPage ? "text-gray-300" : "text-gray-400"}`}
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      {getPages(currentPage, numberOfPages, onPageSelect)}
      <div className="-mt-px w-0 flex-1 flex justify-end pr-4">
        <button
          onClick={onNext}
          disabled={isLastPage}
          className={`border-t-2 border-transparent pt-4 pb-5 pl-1 inline-flex items-center text-sm font-medium ${isLastPage
            ? "text-gray-300 cursor-not-allowed"
            : "text-gray-500 hover:text-gray-700 hover:border-lime-600 cursor-pointer"
            }`}
        >
          Next
          <ArrowNarrowRightIcon
            className={`ml-3 h-5 w-5 ${isLastPage ? "text-gray-300" : "text-gray-400"}`}
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
}
