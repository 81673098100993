import React from "react";
import { Coupon } from "../../types/coupon";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { toast } from "react-toastify";

type CouponsTableProps = {
  coupons: Coupon[];
  onClick: (couponId: number) => void;
  onDelete: (couponId: number) => void;
};

const CouponsTable: React.FC<CouponsTableProps> = ({ coupons, onClick, onDelete }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            % Amount
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            $ Amount
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Active
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            One Time
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Created At
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {coupons.map((coupon) => (
          <tr
            key={coupon.id}
            className="hover:bg-gray-50 group"
          >
            <td
              className="px-6 py-4 whitespace-nowrap "
            >
              {coupon.id}
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap cursor-pointer group-hover:bg-gray-50"
              onClick={() => {
                // Copy coupon name to clipboard
                navigator.clipboard.writeText(coupon.name);
                // Show success toast
                toast.success(`${coupon.name} copied to clipboard`);
              }}
            >
              {coupon.name}
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap "
            >
              {coupon.amount !== null && coupon.amount !== undefined ? `${coupon.amount}%` : "-"}
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap "
            >
              {coupon.exact_amount !== null && coupon.exact_amount !== undefined ? `$${coupon.exact_amount}` : "-"}
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap "
            >
              <span
                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${coupon.active
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
                  }`}
              >
                {coupon.active ? "Active" : "Inactive"}
              </span>
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap "
            >
              {coupon.one_time ? "Yes" : "-"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-center">
              {dayjs(coupon.created_at).format('YYYY MMM DD')}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-center">
              <div className="flex space-x-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(coupon.id);
                  }}
                  className="text-gray-400 hover:text-lime-600 transition-colors duration-200"
                >
                  <PencilIcon className="h-5 w-5" />
                  <span className="sr-only">Edit coupon</span>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(coupon.id);
                  }}
                  className="text-gray-400 hover:text-red-600 transition-colors duration-200"
                >
                  <TrashIcon className="h-5 w-5" />
                  <span className="sr-only">Diactivate coupon</span>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CouponsTable;
