import React, { useCallback, useContext } from 'react';
import SettingsCard from '../../components/SettingsCard/SettingsCard';
import { DrawerContext } from '../../context/DrawerContext';
import { useHistory } from 'react-router-dom';
import {
  InboxIcon,

  SiteSettings,
} from '../../components/AllSvgIcon';
import { TagIcon } from '@heroicons/react/outline';

export default function Settings() {
  const { state, dispatch } = useContext(DrawerContext);
  const history = useHistory();
  const openNotificationSettings = useCallback(
    () =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'NOTIFICATION_SETTINGS_FORM'
      }),
    [dispatch]
  );

  const openTiersForm = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'TIER_SETTINGS_FORM' }),
    [dispatch]
  );

  return (
    <div className="container mx-auto flex flex-col md:flex-row">
      <div className="md:w-1/2 mb-4 md:mb-0 p-4">
        <SettingsCard
          icon={<InboxIcon width="56px" height="56px" />}
          title="Notification Settings"
          subtitle="Update notification settings"
          onClick={() => history.push('/settings/announcements')}
        />
      </div>
      <div className="md:w-1/2 mb-4 md:mb-0 p-4">
        <SettingsCard
          icon={<SiteSettings />}
          title="Tier Settings"
          subtitle="Update Cleaner Tiers"
          onClick={openTiersForm}
        />
      </div>
      <div className="md:w-1/2 mb-4 md:mb-0 p-4">
        <SettingsCard
          icon={<TagIcon width="56px" height="56px" />}
          title="Tag Settings"
          subtitle="Update Cleaner Tags"
          onClick={() => history.push('/settings/tags')}
        />
      </div>
    </div>
  );
}
