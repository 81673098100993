import React, { createContext, useReducer } from 'react';

type DrawerState = {
  isOpen: boolean;
  drawerComponent: any;
  loading: boolean;
  errors: string;
  data: any & { onSubmit?: (data: any) => void; onDelete?: () => void };
};
type Action = any;

const reducer = (state: DrawerState, action: Action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        isOpen: true,
        errors: null,
        loading: false,
        drawerComponent: action.drawerComponent,
        data: action.data
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        isOpen: false,
        errors: null,
        loading: false,
        drawerComponent: null,
        data: null
      };
    case 'UPDATE_DRAWER_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };
    case 'SET_DRAWER_ERROR':
      return {
        ...state,
        errors: action.error,
        data: {
          ...state.data
        }
      };
    case 'SET_DRAWER_LOADING':
      return {
        ...state,
        loading: action.loading,
        data: {
          ...state.data
        }
      };
    default:
      return state;
  }
};

export const DrawerContext = createContext<Partial<any>>({});

type ProfileProviderProps = {
  initData: DrawerState;
};
export const DrawerProvider: React.FunctionComponent<ProfileProviderProps> = ({
  children,
  initData
}) => {
  const [state, dispatch] = useReducer(reducer, initData);
  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
