import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import clsx from "clsx";

const loader = new Loader({
  apiKey: 'AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM',
  version: "weekly",
  libraries: ["places"]
});

type AddressSearchProps = {
  onSelectAddress: (address: string) => void;
  initialAddress?: string;
};

const AddressSearch = ({ onSelectAddress, initialAddress = "" }: AddressSearchProps) => {
  // import AutocompleteService = google.maps.places.AutocompleteService;
  // import AutocompletePrediction = google.maps.places.AutocompletePrediction;

  const [searchTerm, setSearchTerm] = useState<string>(initialAddress);
  const [results, setResults] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [service, setService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [address, setAddress] = useState<string>(initialAddress);

  useEffect(() => {

    loader.importLibrary("places")
      .then(() => {
        const service = new google.maps.places.AutocompleteService();
        setService(service);
      })
      .catch((e) => {
        console.error("Error loading Google Maps API");
        console.error(e);
      });
  }, []);

  const handleInputChange = async (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 3 && service) {
      await service?.getPlacePredictions({
        input: e.target.value.toString(),
        language: 'en',
        componentRestrictions: { country: 'ca' }
      }, (results) => {
        setResults(results ?? []);
      });
    }
  };

  return (
    <div className='mx-auto'>
      <Combobox as="div" value={address} onChange={(val) => {
        setAddress(val ?? "");
        onSelectAddress(val ?? "");
        setSearchTerm(val ?? "");
      }}>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
            onChange={handleInputChange}
            placeholder={'Your Address'}
            value={searchTerm}
            displayValue={(searchItem: google.maps.places.AutocompletePrediction) => searchItem.description}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {results.length > 0 && (
            <Combobox.Options
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.map((result) => (
                <Combobox.Option
                  key={result.place_id}
                  value={result.description}
                  className={({ active }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-lime-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={clsx('block truncate', selected && 'font-semibold')}>{result.description}</span>

                      {selected && (
                        <span
                          className={clsx(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-lime-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export default AddressSearch;