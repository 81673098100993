import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import AddressSearch from '../../components/AddressSearch'

const regions = ['Calgary', 'Edmonton', 'Vancouver']

export default function SearchForm2({ onSearch }) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      region: '',
      address: '',
      dateFrom: '',
      dateTo: '',
    },
  })

  const onSubmit = (data) => {
    onSearch(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 w-full mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="space-y-2">
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
          Address
        </label>
        <Controller
          name="address"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AddressSearch
              initialAddress={value}
              onSelectAddress={onChange}
            />
          )}
        />
      </div>

      <div className="space-y-2 w-64">
        <label htmlFor="region" className="block text-sm font-medium text-gray-700 ">
          Region
        </label>
        <Controller
          name="region"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              id="region"
              className="w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
            >
              <option value="">Select a region</option>
              {regions.map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>
          )}
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-4 w-64">
        {/* <div className="flex-1 space-y-2">
          <label htmlFor="dateFrom" className="block text-sm font-medium text-gray-700">
            Date From
          </label>
          <Controller
            name="dateFrom"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="date"
                id="dateFrom"
                className="w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
              />
            )}
          />
        </div> */}

        <div className="flex-1 space-y-2">
          <label htmlFor="dateTo" className="block text-sm font-medium text-gray-700">
            Date To
          </label>
          <Controller
            name="dateTo"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="date"
                id="dateTo"
                className="w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
              />
            )}
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-lime-600 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      >
        Search
      </button>
    </form>
  )
}