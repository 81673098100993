import React, { ReactElement, useCallback, useContext, useEffect, useState, Fragment } from 'react';
import { CheckCircleIcon, EmojiSadIcon, LightBulbIcon, XCircleIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { get, post, put } from '../../helper/fetch';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { DrawerContext } from '../../context/DrawerContext';
import { toast } from 'react-toastify';
import { Booking } from '../../settings/bookingTypes';
import Comments from '../../components/Comments/Comments';
import { Dialog, Transition } from "@headlessui/react";
import BookingOffersViewGrid from "../BookingOffersViewGrid/BookingOffersViewGrid";
import AvailabilitySearcher from "../AvailabilitySearcher/AvailabilitySearcher";

const getStatus = (status): ReactElement => {
  switch (status) {
    case 'accepted': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'requested': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <LightBulbIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-yellow-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'rejected': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <XCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'cancelled':
    case 'canceled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
    }
    case 'confirmed':
    case 'completed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-2.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    default: {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">???</p>
      );
      break;
    }
  }
};

type Section =
  | 'RECEIPT'
  | 'EXTRAS'
  | 'CLIENT'
  | 'CLEANER'
  | 'DATE_TIME'
  | 'STATUS'
  | 'PROPERTY';

type GroupBooking = {
  id: number;
  status: string;
  booking_date: string;
  created_at: string;
  details: {
    bookingIds: number[];
  };
};

export default function SingleBookingView() {
  const { id } = useParams<{ id: string }>();
  const [booking, setBooking] = useState({} as Booking);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [messageLoading, setMessageLoading] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [groupBookings, setGroupBookings] = useState<GroupBooking | null>(null);
  const { state, dispatch } = useContext(DrawerContext);
  const [notes, setNotes] = useState([]);
  const [receiptHistory, setReceiptHistory] = useState([]);
  const [viewReceiptHistory, setViewReceiptHistory] = useState(false);
  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
  const [isOffersGridDialogOpen, setIsOffersGridDialogOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch]
  );

  const constUpdateTextBoxmsg = (e: any) => {
    setNewMessage(e.target.value);
  };

  async function loadBookingData(id) {
    try {
      const b = await get(`/bookings/${id}`);
      setBooking(b);
      fetchConvo(b.conversation_id);
      fetchNotes(b.id);
      fetchGroupBookings(b.group_booking_id);
      fetchReceiptHistory(b.id);
      fetchOffers(b.id);
    } catch (e) {
      setError('could not fetch booking ' + id);
    }
  }

  async function fetchConvo(conversionId) {
    try {
      const msgs = await get(`/bookings/conversation/${conversionId}`);
      setMessagesList(msgs);
    } catch (e) {
      console.log('could not fetch conversation', e);
    }
  }

  async function fetchNotes(bookingId) {
    try {
      const notes = await get(`/bookings/${bookingId}/notes`);
      setNotes(notes || []);
    } catch (e) {
      console.log('could not fetch notes', e);
    }
  }

  async function fetchGroupBookings(groupBookingId) {
    if (!groupBookingId) return;
    try {
      const groupBookings = await get(`/bookings/group/${groupBookingId}`);
      setGroupBookings(groupBookings);
    } catch (e) {
      console.log('could not fetch group bookings', e);
    }
  }

  async function fetchReceiptHistory(bookingId) {
    try {
      const receiptHistory = await get(`/bookings/${bookingId}/receipt/history`);
      setReceiptHistory(receiptHistory);
    } catch (e) {
      console.log('could not fetch receipt history', e);
    }
  }

  useEffect(() => {
    setError(null);
    const fetchData = async () => await loadBookingData(id);
    if (id) {
      fetchData();
    }
  }, []);

  const fetchOffers = async (bookingId) => {
    try {
      const response = await get(`/cleanerlistings/${bookingId}/offers`);
      setOffers(response.offers);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  useEffect(() => {
    if (booking.id) {
      fetchOffers(booking.id);
    }
  }, [booking.id]);

  const handleDeleteOffer = async () => {
    await fetchOffers(booking.id);
  };

  const onOfferSent = async () => {
    await fetchOffers(booking.id);
    setIsOfferDialogOpen(false);
  };

  async function updateSection(bookingId, sectionType: Section, data) {
    if (sectionType === 'RECEIPT') {
      const response = await put(`/bookings/${bookingId}/receipt`, {
        receipt: data.receipt
      });
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Receipt updated');
      } else {
        toast.error('Could not update receipt');
      }
      if (data.extras) {
        await updateSection(
          bookingId,
          'EXTRAS',
          data.extras.map((e) => e.id)
        );
      }
    }

    if (sectionType === 'CLEANER') {
      const response = await put(`/bookings/${bookingId}/cleaners`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning transfered!');
      } else {
        toast.error('Could not transfer cleaning');
      }
    }
    if (sectionType === 'CLIENT') {
      const response = await put(`/bookings/${bookingId}/clients`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning transfered!');
      } else {
        toast.error('Could not transfer cleaning');
      }
    }
    if (sectionType === 'PROPERTY') {
      const response = await put(`/bookings/${bookingId}/property`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning property updated');
      } else {
        toast.error('Could not update cleaning property');
      }
    }
    if (sectionType === 'DATE_TIME') {
      const response = await put(`/bookings/${bookingId}/datetime`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Date and time updated!');
      } else {
        toast.error('Could not update date and time');
      }
    }
    if (sectionType === 'STATUS') {
      const response = await put(`/bookings/${bookingId}/status`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('status updated!');
      } else {
        toast.error('Could not update status');
      }
    }
    if (sectionType === 'EXTRAS') {
      const response = await put(`/bookings/${bookingId}/extras`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('extras updated!');
      } else {
        toast.error('Could not update extras');
      }
    }
    closeDrawer();
  }

  const calculateHours = (startHour: number, startMinute: number, endHour: number, endMinute: number) => {
    let from = startHour;
    if (startMinute > 0) from += 0.5;
    let to = endHour;
    if (endMinute > 0) to += 0.5;
    return to - from;
  }

  async function openEditSection(sectionType: Section, data: any) {
    if (sectionType === 'RECEIPT') {
      let cleaner = null;
      try {
        cleaner = await get(`/cleaners/${booking.cleaner}`);
      } catch (e) {
        toast.error('Could not fetch cleaner');
        return;
      }
      const tierLimit = cleaner?.listing?.tier?.max_price || 100;
      const hours = calculateHours(booking.start_hour, booking.start_minute, booking.end_hour, booking.end_minute);
      let amount = 0;
      let exact_amount = 0;
      let couponCode = null;
      if (booking.coupon_code) {
        try {
          const coupon = await get(`/coupons/code/${booking.coupon_code}`);
          amount = coupon?.amount || 0;
          exact_amount = coupon?.exact_amount || 0;
          couponCode = coupon?.name || null;
        } catch (e) {
          toast.error('Could not fetch coupon: ' + booking.coupon_code);
        }
      }
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_RECEIPT',
        data: {
          id: booking.id,
          receipt: data,
          extras: booking.extras,
          hours: hours,
          pricePerHour: booking.receipt.hourly_rate,
          tierLimit: tierLimit,
          couponCode: couponCode,
          couponAmount: amount,
          couponExactAmount: exact_amount,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'EXTRAS') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_EXTRAS',
        data: {
          id: booking.id,
          initialExtras: booking.extras.map((e) => e.id),
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'CLEANER') {
      //TODO: fetch cleaner
      try {
        const cleaner = data ? await get(`/cleaners/${data}`) : null;
        toast.success('Fetched cleaner');
        dispatch({
          type: 'OPEN_DRAWER',
          drawerComponent: 'EDIT_BOOKING_CLEANER',
          data: {
            id: booking.id,
            cleaner: cleaner,
            onSubmit: (data) => updateSection(booking.id, sectionType, data)
          }
        });
      } catch (e) {
        toast.error('Could not fetch cleaner');
        return;
      }
    }
    if (sectionType === 'CLIENT') {
      const client = data ? await get(`/clients/${data}`) : null;
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_CLIENT',
        data: {
          id: booking.id,
          client: client,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'PROPERTY') {
      const client = data ? await get(`/clients/${data}`) : null;
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_PROPERTY',
        data: {
          id: booking.id,
          client: client,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'DATE_TIME') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_DATE_TIME',
        data: {
          id: booking.id,
          dateTime: {
            start_hour: booking.start_hour,
            start_minute: booking.start_minute,
            end_hour: booking.end_hour,
            end_minute: booking.end_minute,
            booking_date: booking.booking_date,
            date_override: booking.date_override,
          },
          status: booking.status,
          recurrance: booking.recurrance,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'STATUS') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_STATUS',
        data: {
          id: booking.id,
          status: booking.status,
          recurrance: booking.recurrance,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
  }

  const submitNewMessage = async (newMessage, conversationId) => {
    try {
      const res = await post('/bookings/conversations/' + conversationId, {
        newMessage: newMessage
      });
      if (res.status !== 201) {
        toast.error('Could not add message');
        return false;
      }
      toast.success('Added message');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const submitNewMessageToBooking = async (newMessage, conversationId) => {
    //TODO: nearly guaranteed to be total garbage. Need to use reducer instead of useState
    setMessageLoading(true);
    setNewMessage('');
    try {
      const res = await submitNewMessage(newMessage, conversationId);
      if (res) {
        const msg = {
          createdAt: new Date(),
          sender: {
            role: 'client',
            name: ''
          },
          content: newMessage
        };
        setMessagesList((messagesList) => {
          return [...messagesList, msg];
        });
      }
    } catch (e) {
      //TODO: error msg please
    }
    setMessageLoading(false);
  };

  async function cloneBookingAction(id: number) {
    try {
      const res = await get(`/bookings/${id}/clone`);
      if (!res.bookingId) {
        toast.error('Could not clone booking');
        return false;
      }
      toast.success('Cloned booking!');
      window.open(`/bookings/${res.bookingId}`, '_blank');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function offerToCleanersAction(id: number) {
    setIsOfferDialogOpen(true);
  }


  return (
    <div className="relative min-h-screen bg-gray-100">
      {/* Dialog for Offer to Cleaners */}
      <Dialog
        open={isOfferDialogOpen}
        onClose={() => setIsOfferDialogOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-3xl rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium mb-4">Offer to Cleaners</Dialog.Title>
            <AvailabilitySearcher
              date={booking.booking_date}
              address={booking.bookingProperty?.address}
              bookingId={booking.id}
              onOfferSent={onOfferSent}
            />
            <div className="mt-4 flex justify-start">
              <button
                onClick={() => setIsOfferDialogOpen(false)}
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Dialog for Show Offers */}
      <Transition appear show={isOffersGridDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOffersGridDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-4"
                  >
                    Booking Offers
                  </Dialog.Title>
                  <BookingOffersViewGrid
                    offers={offers}
                    onOfferDeleted={handleDeleteOffer}
                  />
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOffersGridDialogOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <main className="py-10">

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section
              id="booking-overview"
              className="bg-white shadow sm:rounded-lg max-w-3xl mx-auto px-4 py-5 sm:px-6 lg:max-w-7xl"
            >
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {booking?.id} {booking.recurrance},{' '}
                    {booking.meta?.source
                      ? `source: (${booking.meta?.source})`
                      : ''}
                  </h1>

                  {/* Group Booking Info */}
                  {booking?.group_booking_id && (
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Part of #{booking?.group_booking_id} group booking
                      </p>
                      <div className="flex flex-row">
                        {groupBookings && groupBookings.details && groupBookings.details?.bookingIds?.length > 0 && (
                          groupBookings.details?.bookingIds?.map((b) => {
                            return <p className="text-sm font-medium text-gray-500 hover:cursor-pointer underline mr-2"
                              onClick={() => window.open(`/bookings/${b}`)}
                              key={b}
                            >
                              #{b}
                            </p>;
                          })
                        )}
                      </div>
                    </div>
                  )}

                  {/* Status */}
                  {booking && booking.status && <div className="flex items-center space-x-5">
                    <h2 className="text-lg font-medium text-gray-900">{booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}</h2>
                    {getStatus(booking.status)}
                  </div>}

                  {/* Cleaner Info */}
                  <div className="flex items-center space-x-5 mt-2">
                    <div className="flex items-center">
                      <p
                        className="text-lg text-gray-900 cursor-pointer hover:underline"
                        onClick={() => history.push(`/cleaners/${booking?.listing_id}`)}
                      >
                        Cleaner: {booking?.bookingCleaner?.given_name}{' '}
                        {booking?.bookingCleaner?.family_name}
                      </p>
                      <p
                        className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          openEditSection('CLEANER', booking.cleaner);
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  </div>

                  {/* Client Info */}
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <p
                        className="text-lg text-gray-900 cursor-pointer hover:underline"
                        onClick={() => history.push(`/clients/${booking?.bookingClient?.id}`)}
                      >
                        Client: {booking?.bookingClient?.given_name}{' '}
                        {booking?.bookingClient?.family_name}
                      </p>
                      <p
                        className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          openEditSection('CLIENT', booking.client);
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  </div>

                  {/* Date Info */}
                  <div className="flex items-center space-x-5">
                    <p className="text-sm font-medium text-gray-500">
                      Date{' '}
                      <time dateTime="2020-08-25">
                        {dayjs(booking?.booking_date).format('YYYY-MMM-DD')},{' '}
                        {booking?.start_hour}:
                        {booking?.start_minute === 0 ? '00' : booking?.start_minute}{' '}
                        - {booking?.end_hour}:
                        {booking?.end_minute === 0 ? '00' : booking?.end_minute}
                      </time>
                    </p>
                    {booking?.date_override && (
                      <p className={'text-sm font-medium text-gray-500'}>
                        One Time override Date {booking?.date_override}
                      </p>
                    )}
                    <p
                      className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                      onClick={(e) => {
                        openEditSection('DATE_TIME', booking.cleaner);
                      }}
                    >
                      Edit
                    </p>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex flex-column items-center space-x-5 py-4">
                    <button type="button"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
                      onClick={() => cloneBookingAction(booking.id)}
                    >
                      Clone
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      onClick={() => setIsOfferDialogOpen(true)}
                    >
                      Offer to Cleaners
                    </button>
                    {offers.length > 0 && (
                      <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => setIsOffersGridDialogOpen(true)}
                      >
                        Show Offers
                      </button>
                    )}
                    <p>{booking?.cloned_from ? `(cloned from ${booking?.cloned_from})` : ''}</p>
                  </div>
                </div>
              </div>
            </section>

            <section id='notes'>
              {booking && (
                <Comments
                  comments={notes}
                  onSubmit={(newNote) => {
                    post(`/bookings/${id}/notes`, { note: newNote })
                      .then((b) => {
                        if (b.status !== 201) {
                          throw Error('could not add note');
                        } else {
                          toast.success('saved new note');
                          window.location.reload();
                        }
                      })
                      .catch((e) => toast.error(e));
                  }}
                />
              )}
            </section>

            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">

                <div className="flex flex-col border-t border-gray-200 px-4 py-4">
                  <div id={'details'}>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Created at</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {dayjs(booking.created_at).format('YYYY-MMM-DD')}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Updated at</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking.updated_at ? dayjs(booking.updated_at).format('YYYY-MMM-DD') : 'not entered'}
                      </dd>
                    </div>
                    <dt className="text-sm font-medium text-gray-500">
                      House Info
                    </dt>
                    {booking.client && <p
                      className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                      onClick={(e) => {
                        openEditSection('PROPERTY', booking.client);
                      }}
                    >
                      Edit
                    </p>}
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Address</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.apartment_number &&
                          `#${booking?.bookingProperty?.apartment_number}`}{' '}
                        {booking?.bookingProperty?.address}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Entrance Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.entrance_info}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Parking Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.parking_info}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Size</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.size} (size does{' '}
                        {booking?.bookingProperty?.size_includes_basement
                          ? ''
                          : 'not'}{' '}
                        include basement)
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Rooms / Bathrooms</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.number_of_rooms} /{' '}
                        {booking?.bookingProperty?.number_of_bathrooms}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Other Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.other_info}
                      </dd>
                    </div>
                    {booking?.started_at && (
                      <>
                        <div className="flex items-center py-4">
                          <dt className="text-base w-2/6">Started At</dt>
                          <dd className="ml-2 text-sm w-4/6 font-bold text-gray-900">
                            {booking?.started_at}
                          </dd>
                        </div>
                        <div className="flex items-center py-4">
                          <dt className="text-base w-40">Stopped At</dt>
                          <dd className="ml-2 text-sm font-bold text-gray-900">
                            {booking?.stopped_at}
                          </dd>
                        </div>
                      </>
                    )}

                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Coupon</dt>
                      <div className="w-4/6 flex flex-row">
                        <dd className="ml-2 text-sm w-full text-gray-900">
                          {booking.coupon_code}
                        </dd>

                      </div>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Extras</dt>
                      <div className="w-4/6 flex flex-row">
                        <dd className="ml-2 text-sm w-full text-gray-900">
                          {booking.extras &&
                            booking.extras.length > 0 &&
                            booking.extras.map((e) => <p key={e.id}>{e.name}</p>)}
                        </dd>
                        <p
                          className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            openEditSection('EXTRAS', booking.receipt);
                          }}
                        >
                          Edit
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">
                        Can cleaning time be extended?
                      </dt>
                      <dd className="ml-2 text-lg w-4/6 text-gray-900">
                        {booking?.can_extend_hours ? 'Yes' : 'No'}
                      </dd>
                    </div>

                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">
                        Client notified of upcomming booking?
                      </dt>
                      <dd className="ml-2 text-lg w4/6 text-gray-900">
                        {booking?.notified ? 'Yes' : 'No'}
                      </dd>
                    </div>
                  </div>

                  <div id={'receipt'} className={'py-4'}>
                    <div className={'flex flex-row'}>
                      <dt className="text-sm font-medium text-gray-500">
                        Receipt
                      </dt>
                      <p
                        className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          openEditSection('RECEIPT', booking.receipt);
                        }}
                      >
                        Edit
                      </p>
                      <p
                        className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          setViewReceiptHistory(!viewReceiptHistory);
                        }}
                      >
                        {!viewReceiptHistory ? 'View History' : 'Hide History'}
                      </p>
                    </div>
                    {!viewReceiptHistory && <>
                      <dl className="border-t border-gray-200 py-6 space-y-6">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Hourly Rate</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.hourly_rate}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Subtotal (Labour + extras)</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.subtotal}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Fee</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            {`$${booking?.receipt?.fee}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Coupon Amount</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            {`$${booking?.receipt?.coupon_covers}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Taxes</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.tax}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-200">
                          <dt className="text-base font-medium">Total</dt>
                          <dd className="text-base font-medium text-gray-900">
                            {`$${booking?.receipt?.total}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between pt-1">
                          <dt className="text-base font-medium">( Cleaner Gets</dt>
                          <dd className="text-base font-medium text-gray-900">
                            -${booking?.receipt?.cleaner_total} )
                          </dd>
                        </div>
                      </dl>
                    </>}

                    {viewReceiptHistory && <>
                      {receiptHistory.map((rh) => {
                        return <div key={rh.id} >
                          <dl className="border-t border-gray-200 py-6 space-y-6">
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Created</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                {dayjs.utc(rh.created_at).tz('America/Edmonton').format('YYYY-MMM-DD: hh:mm')}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Author</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                {rh.author_name || rh.author_id}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Hourly Rate</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                ${rh.hourly_rate}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Subtotal (Labour + extras)</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                ${rh.subtotal}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Fee</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                {`$${rh.fee}`}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Coupon Amount</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                {`$${rh.coupon_covers}`}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Taxes</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                ${rh.tax}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200">
                              <dt className="text-base font-medium">Total</dt>
                              <dd className="text-base font-medium text-gray-900">
                                {`$${rh.total}`}
                              </dd>
                            </div>
                            <div className="flex items-center justify-between pt-1">
                              <dt className="text-base font-medium">( Cleaner Gets</dt>
                              <dd className="text-base font-medium text-gray-900">
                                -${rh.cleaner_total} )
                              </dd>
                            </div>
                          </dl>
                        </div>;
                      }
                      )}
                    </>
                    }


                  </div>
                </div>
              </div>
            </section>

            <section id="chat-section">
              <div className="bg-white shadow sm:rounded-lg p-4">
                {/* Chat Messages Container */}
                <div className="flex flex-col w-full bg-gray-50 rounded-lg p-4 mb-4">
                  <div className="flex flex-col space-y-4 min-h-[300px] max-h-[500px] overflow-y-auto">
                    {messagesList &&
                      messagesList.map((m) => {
                        if (m.sender.role === 'cleaner') {
                          return (
                            <div className="flex flex-col space-y-1" key={'theirs-' + m.id}>
                              <div className="flex items-start">
                                <div className="bg-blue-100 rounded-lg rounded-tl-none px-4 py-2 max-w-[80%]">
                                  <p className="text-sm text-gray-900">{m.content}</p>
                                </div>
                              </div>
                              <p className="text-xs text-gray-500 ml-2">
                                {m.sender.name} • {dayjs(m.createdAt).format('MMM D hh:mm a')}
                              </p>
                            </div>
                          );
                        } else if (m.sender.role === 'client') {
                          return (
                            <div className="flex flex-col space-y-1" key={'theirs2-' + m.id}>
                              <div className="flex items-start">
                                <div className="bg-gray-200 rounded-lg rounded-tl-none px-4 py-2 max-w-[80%]">
                                  <p className="text-sm text-gray-900">{m.content}</p>
                                </div>
                              </div>
                              <p className="text-xs text-gray-500 ml-2">
                                {m.sender.name} • {dayjs(m.createdAt).format('MMM D hh:mm a')}
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div className="flex flex-col items-end space-y-1" key={'mine-' + m.id}>
                              <div className="flex items-start justify-end">
                                <div className="bg-lime-600 rounded-lg rounded-tr-none px-4 py-2 max-w-[80%]">
                                  <p className="text-sm text-white">{m.content}</p>
                                </div>
                              </div>
                              <p className="text-xs text-gray-500 mr-2">
                                ADMIN {m.sender.name} • {dayjs(m.createdAt).format('MMM D hh:mm a')}
                              </p>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>

                {/* Message Input Area */}
                <div className="flex space-x-2">
                  <input
                    type="text"
                    id="msg-input"
                    name="chatInputMain"
                    value={newMessage}
                    onChange={(e) => constUpdateTextBoxmsg(e)}
                    placeholder="Type your message..."
                    className="flex-1 min-w-0 rounded-md border border-gray-300 px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-lime-500"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !messageLoading) {
                        e.preventDefault();
                        submitNewMessageToBooking(newMessage, booking.conversation_id);
                      }
                    }}
                  />
                  <button
                    type="button"
                    disabled={messageLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      submitNewMessageToBooking(
                        newMessage,
                        booking.conversation_id
                      );
                    }}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 disabled:opacity-50"
                  >
                    {messageLoading ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sending...
                      </span>
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}
