import React from 'react';

import { Announcement, AnnouncementWithAuthor } from './AnnouncementManager'
import IndividualCard from './IndividualCard';
type Props = {
  announcements: AnnouncementWithAuthor[]
  onEdit: (announcement: Announcement) => void
  onDelete: (id: number) => void
}

const AnnouncementList: React.FC<Props> = ({ announcements, onEdit, onDelete }) => {
  return (
    <div className="space-y-4">
      {announcements.map((announcement) => (
        <IndividualCard key={announcement.id} announcement={announcement} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </div>
  );
}

export default AnnouncementList;
