import React, { useState, useEffect } from 'react'

import { QuillEditor } from '../../components/QuillEditor';
import { Announcement } from './AnnouncementManager';

interface AnnouncementFormProps {
  onSubmit: (announcement: Omit<Announcement, 'id' | 'created_at' | 'updated_at'>) => void
  onCancel: () => void
}

export default function AnnouncementForm({ onSubmit, onCancel }: AnnouncementFormProps) {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState({ delta: '', html: '' })


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit({ title, content_delta: content.delta, content_html: content.html })
  }

  return (
    <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow">
      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        {/* Title Input */}
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Quill Editor */}
        <div>
          <label
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Content
          </label>
          <QuillEditor
            initialDelta={null}
            onChange={(data) => {
              setContent(data);
            }}
          />
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3 pt-6">
          <button
            type="button"
            onClick={onCancel}
            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

