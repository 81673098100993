import React, { useEffect, useState } from "react";
import { CleanerTag } from "../../types/tags";
import { get } from "../../helper/fetch";

type TagSelectorProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedTags: string[]) => void;
  currentTags: string[];
};

export const TagSelector: React.FC<TagSelectorProps> = ({
  isOpen,
  onClose,
  onSave,
  currentTags,
}) => {
  const [tags, setTags] = useState<CleanerTag[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<Set<string>>(new Set(currentTags));

  useEffect(() => {
    get("/tags")
      .then((data) => setTags(data.data))
      .catch((error) => console.error("Error fetching tags:", error));
  }, []);

  if (!isOpen) return null;

  const toggleTag = (tagId: string) => {
    const newSelection = new Set(selectedTagIds);
    if (newSelection.has(tagId)) {
      newSelection.delete(tagId);
    } else {
      newSelection.add(tagId);
    }
    setSelectedTagIds(newSelection);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium">Select Tags</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-6">
          {tags && tags.map((tag) => (
            <div
              key={tag.id}
              onClick={() => toggleTag(tag.id)}
              className={`cursor-pointer p-3 rounded-lg border ${selectedTagIds.has(tag.id)
                ? "border-lime-600 bg-lime-50"
                : "border-gray-200 hover:border-gray-300"
                }`}
            >
              <div className="flex items-center justify-between">
                <span
                  className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ${tag.client_visible
                    ? "bg-lime-100 text-lime-700"
                    : tag.cleaner_visible
                      ? "bg-yellow-100 text-yellow-700"
                      : "bg-orange-100 text-orange-700"
                    }`}
                >
                  {tag.name}
                </span>
                {selectedTagIds.has(tag.id) && (
                  <svg className="h-5 w-5 text-lime-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(Array.from(selectedTagIds))}
            className="px-4 py-2 bg-lime-600 border border-transparent rounded-md text-sm font-medium text-white hover:bg-lime-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}; 