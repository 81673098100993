import React from "react";
import { Field, Form, Formik } from "formik";
import { CouponSearchParams } from "../../types/coupon";

type CouponSearchFormProps = {
  onSearch: (values: CouponSearchParams) => void;
  onClear: () => void;
  filterParams?: Partial<CouponSearchParams>;
};

export const CouponSearchForm: React.FC<CouponSearchFormProps> = ({
  onSearch,
  onClear,
  filterParams,
}) => {
  const initialValues: CouponSearchParams = {
    name: filterParams?.name || "",
    active: filterParams?.active || undefined,
    one_time: filterParams?.one_time || undefined,
    sortBy: filterParams?.sortBy || "created_at",
    sortOrder: filterParams?.sortOrder || "desc",
    pageNumber: 0,
    numberPerPage: 20,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSearch(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="bg-white shadow-sm p-4 mb-4 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Coupon Name
              </label>
              <Field
                type="text"
                name="name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-600 focus:ring-lime-600"
              />
            </div>

            <div>
              <label htmlFor="sortBy" className="block text-sm font-medium text-gray-700">
                Sort By
              </label>
              <Field
                as="select"
                name="sortBy"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-600 focus:ring-lime-600"
              >
                <option value="created_at">Date Created</option>
                <option value="name">Name</option>
              </Field>
            </div>

            <div>
              <label htmlFor="sortOrder" className="block text-sm font-medium text-gray-700">
                Sort Order
              </label>
              <Field
                as="select"
                name="sortOrder"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-600 focus:ring-lime-600"
              >
                <option value="desc">Descending</option>
                <option value="asc">Ascending</option>
              </Field>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Active Toggle */}
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => setFieldValue("active", values.active === undefined ? true : undefined)}
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-600 focus:ring-offset-2 ${values.active === true ? "bg-lime-600" : "bg-gray-200"
                  }`}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${values.active === true ? "translate-x-5" : "translate-x-0"
                    }`}
                />
              </button>
              <span className="ml-3 text-sm font-medium text-gray-700">
                {values.active === true ? "Active Only" : "Active & Inactive"}
              </span>
            </div>

            {/* One Time Toggle */}
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => setFieldValue("one_time", values.one_time === undefined ? true : undefined)}
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-600 focus:ring-offset-2 ${values.one_time === true ? "bg-lime-600" : "bg-gray-200"
                  }`}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${values.one_time === true ? "translate-x-5" : "translate-x-0"
                    }`}
                />
              </button>
              <span className="ml-3 text-sm font-medium text-gray-700">
                {values.one_time === true ? "One Time Only" : "One Time & Recurring"}
              </span>
            </div>
          </div>

          <div className="mt-4 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => {
                onClear();
                // Reset form values
                setFieldValue("active", undefined);
                setFieldValue("one_time", undefined);
              }}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Clear
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-600 hover:bg-lime-700"
            >
              Search
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CouponSearchForm;
