import React, { useState, Fragment, useRef, useEffect } from "react";
import { GoogleMap, LoadScript, Polygon, Marker, DrawingManager } from "@react-google-maps/api";
import { Dialog, Transition } from "@headlessui/react";
import { get, post, put, del } from '../helper/fetch';

type Coordinate = {
  lat: number;
  lng: number;
};

type Bounds = {
  north: number;
  south: number;
  east: number;
  west: number;
};


type AreasMapEditorProps = {
  initialBounds?: Bounds;
  onSave?: (coords: Bounds) => void;
};

type RegionCenter = {
  [key in Region]: { lat: number; lng: number };
};

type Region = "Calgary" | "Edmonton" | "Vancouver";
type SavedPolygon = {
  id?: number;
  name?: string;
  region?: string;
  color?: string;
  polygon: google.maps.Polygon;
  isSaved?: boolean;
};

type PolygonMapping = Map<google.maps.Polygon, SavedPolygon>;

const AreasMapEditor: React.FC<AreasMapEditorProps> = (props: AreasMapEditorProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState<SavedPolygon>(null);
  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<Region>("Calgary");

  const [existingPolygons, setExistingPolygons] = useState<SavedPolygon[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<Region>("Calgary");
  const mapRef = useRef<google.maps.Map | null>(null);

  const [polygonMapping, setPolygonMapping] = useState<PolygonMapping>(new Map());

  useEffect(() => {
    getExistingPolygons();
  }, []);

  function getExistingPolygons() {
    get('/settings/maps/areamap')
      .then(data => {
        const polygons = data.map((area: any) => {
          const polygon: SavedPolygon = {
            id: area.id,
            name: area.name,
            region: area.region,
            color: generateRandomColor(),
            polygon: new google.maps.Polygon({
              paths: area.paths,
              fillColor: area.fillColor,
              strokeColor: area.strokeColor,
            }),
            isSaved: true
          };
          return polygon;
        });

        setExistingPolygons(polygons);

      })
      .catch(err => console.error(err));
  }

  const createSquarePolygon = (center: Coordinate): Coordinate[] => {
    const offset = 0.01;
    return [
      { lat: center.lat + offset, lng: center.lng - offset },
      { lat: center.lat + offset, lng: center.lng + offset },
      { lat: center.lat - offset, lng: center.lng + offset },
      { lat: center.lat - offset, lng: center.lng - offset },
    ];
  };

  const regionCenters: RegionCenter = {
    Calgary: { lat: 51.0447, lng: -114.0719 },
    Edmonton: { lat: 53.5461, lng: -113.4938 },
    Vancouver: { lat: 49.2827, lng: -123.1207 },
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRegion = event.target.value as Region;
    setSelectedRegion(newRegion);
    if (mapRef.current) {
      mapRef.current.panTo(regionCenters[newRegion]);
    }
  };

  const generateRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const generateTemporaryId = (): number => {
    const existingIds = existingPolygons.map(p => p.id || 0);
    let tempId = -1;
    while (existingIds.includes(tempId)) {
      tempId--;
    }
    return tempId;
  };

  const handleMapRightClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const color = generateRandomColor();
      const newGooglePolygon = new google.maps.Polygon({
        paths: createSquarePolygon({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }),
        fillColor: color,
        strokeColor: color,
      });

      const newSavedPolygon: SavedPolygon = {
        id: generateTemporaryId(),
        name: "",
        region: selectedRegion,
        color: color,
        polygon: newGooglePolygon,
        isSaved: false
      };

      setExistingPolygons([...existingPolygons, newSavedPolygon]);
    }
  };

  const editPolygon = (savedPolygon: SavedPolygon) => {
    // Find the actual Google Maps Polygon instance from our mapping
    const mappedPolygon = Array.from(polygonMapping.entries())
      .find(([_, saved]) => saved.id === savedPolygon.id)?.[0];

    if (!mappedPolygon) {
      console.error("Could not find mapped polygon");
      return;
    }

    // Get current paths from the mapped polygon
    const currentPaths = mappedPolygon.getPath().getArray().map(latLng => ({
      lat: latLng.lat(),
      lng: latLng.lng()
    }));

    setName(savedPolygon.name || "");
    setRegion(savedPolygon.region as Region || selectedRegion);
    setSelectedPolygon({
      ...savedPolygon,
      polygon: new google.maps.Polygon({
        ...savedPolygon.polygon,
        paths: currentPaths
      }),
      isSaved: savedPolygon.isSaved
    });
    setIsDialogOpen(true);
  };

  const savePolygon = async (name: string, region: string, polygon: google.maps.Polygon, id?: number) => {
    const paths = polygon.getPath().getArray();
    let resp;
    if (id) {
      resp = await put(`/settings/maps/areamap/${id}`, {
        paths: paths,
        region: region,
        name: name,
      });
    } else {
      resp = await post('/settings/maps/areamap', {
        paths: paths,
        region: region,
        name: name,
      });
    }
    console.log("resp", resp);
    if (resp.ok) {
      setIsDialogOpen(false);
      getExistingPolygons();
    }
  };

  const deletePolygon = async (id?: number) => {
    if (!id) return;

    try {
      const response = await del(`/settings/maps/areamap/${id}`);

      if (response.ok) {
        setIsDialogOpen(false);
        getExistingPolygons();
      } else {
        console.error("Failed to delete polygon");
      }
    } catch (error) {
      console.error("Error deleting polygon:", error);
    }
  };

  const registerPolygonEventsForPolygon = (loadedPolygon: google.maps.Polygon, savedPolygon: SavedPolygon) => {
    setPolygonMapping(prevMapping => {
      const newMapping = new Map(prevMapping);
      newMapping.set(loadedPolygon, savedPolygon);
      return newMapping;
    });
  };

  const removePolygonMapping = (loadedPolygon: google.maps.Polygon) => {
    setPolygonMapping(prevMapping => {
      const newMapping = new Map(prevMapping);
      newMapping.delete(loadedPolygon);
      return newMapping;
    });
  };

  return (
    <>
      <div className="flex flex-col h-dvh">
        <div className="p-4 bg-gray-100">
          <select
            value={selectedRegion}
            onChange={handleRegionChange}
            className="block w-full px-3 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="Calgary">Calgary</option>
            <option value="Edmonton">Edmonton</option>
            <option value="Vancouver">Vancouver</option>
          </select>
        </div>
      </div>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM"
      >
        <GoogleMap
          mapContainerClassName={"min-h-full h-96"}
          id="cleaner-working-map"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          zoom={9}
          center={regionCenters[selectedRegion]}
          options={{
            fullscreenControl: true,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          onRightClick={handleMapRightClick}
          onDblClick={null}
        >

          {existingPolygons.map((polygon, index) => (
            <>
              <Polygon
                key={index}
                draggable={true}
                editable={true}
                onLoad={(loadedPolygon) => registerPolygonEventsForPolygon(loadedPolygon, polygon)}
                onRightClick={() => editPolygon(polygon)}
                onDblClick={(e) => {
                  editPolygon(existingPolygons[index]);
                }}
                options={{
                  fillColor: polygon.color || generateRandomColor(),
                  fillOpacity: polygon.isSaved ? 0.5 : 0.3,
                  strokeColor: polygon.color || generateRandomColor(),
                  strokeOpacity: polygon.isSaved ? 0.5 : 0.3,
                  strokeWeight: polygon.isSaved ? 2 : 1,
                }}
                paths={polygon.polygon.getPath().getArray()}
              />
              <Marker position={{ lat: 43.65107, lng: -79.347015 }} label={"test"} />
            </>
          ))}
        </GoogleMap>
        <div className={"mt-3 flex items-center justify-between"}>
          <button
            type="submit"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            Save
          </button>
        </div>
      </LoadScript>

      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsDialogOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Polygon Details ({selectedPolygon?.isSaved ? "Saved" : "Not Saved"})
                </Dialog.Title>
                <div className="mt-4">
                  <label htmlFor="label" className="block text-sm font-medium text-gray-700">
                    Label
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                    Region
                  </label>
                  <select
                    id="region"
                    name="region"
                    value={region}
                    onChange={(e) => setRegion(e.target.value as Region)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="Calgary">Calgary</option>
                    <option value="Edmonton">Edmonton</option>
                    <option value="Vancouver">Vancouver</option>
                  </select>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  {selectedPolygon?.isSaved && (
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      onClick={() => deletePolygon(selectedPolygon.id)}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => {
                      savePolygon(name, region, selectedPolygon.polygon, selectedPolygon.isSaved ? selectedPolygon.id : undefined);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AreasMapEditor;