import React, { useEffect, useState } from 'react';
import StackedList, {
  ListingAvailability
} from '../../components/StackedList/StackedList';
import AvailabilitySearchForm from '../../components/AvailabilitySearchForm/SearchForm';
import { get } from '../../helper/fetch';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import SearchForm2 from '../../components/AvailabilitySearchForm/SearchForm2';

type searchParams = {
  date: string;
  address: string;
};
export default function CleanerAvailabilitySearch() {
  const history = useHistory();
  let location = useLocation();
  const [searchParams, setSearchParams] = useState<searchParams>(null);
  const [listingsAvailability, setListingsAvailability] = useState(
    [] as ListingAvailability[]
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchParams = {} as searchParams;
      if (params.get('date')) {
        searchParams.date = params.get('date');
      }
      if (params.get('address')) {
        searchParams.address = params.get('address');
      }
      console.log('searchParams', searchParams);
      setSearchParams(searchParams);
    }
  }, []);

  async function searchForCleaners(searchPage: number, searchData: any) {
    setLoading(true);
    setSearchParams(searchData);
    let urlParams = null;
    if (searchData) {
      urlParams = new URLSearchParams({
        ...searchData,
        date: searchData.dateTo,
        pageNumber: (searchPage || 0).toString()
      });
    } else {
      urlParams = new URLSearchParams({
        numberPerPage: (20).toString(),
        pageNumber: (searchPage || 0).toString()
      });
    }
    urlParams && history.push({ search: urlParams.toString() });
    get('/cleanerlistings/availability?' + urlParams)
      .then((bs) => {
        if (!bs) throw new Error('result empty');
        setListingsAvailability(bs);
      })
      .catch((e) => {
        setError('could not fetch coupons');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      {loading ? <LoadingOverlay /> : null}
      <SearchForm2 onSearch={(data) => {
        searchForCleaners(0, data);
      }} />
      {/* <AvailabilitySearchForm
        onSearch={(data) => {
          searchForCleaners(0, data);
        }}
        onClear={() => setSearchParams(null)}
        filterParams={searchParams}
      /> */}

      <StackedList items={listingsAvailability} />
    </div>
  );
}
