import React, { useEffect, useState } from "react"
import { CheckIcon } from "@heroicons/react/solid"
import { Extra } from "../settings/bookingTypes";
import { get } from "../helper/fetch";

export type ExtraSelectItem = Extra & { checked: boolean, value: string, label: string, adds_hours?: number | null };

type ExtraPickerProps = {
  onSelectionChange?: (selectedExtras: ExtraSelectItem[], addedHours: number) => void;
  closeButton?: JSX.Element;
}

// 1 Cleaning Supplies','
// 2 Same Day Cleaning(within 24 hours),
// 3 Oven Cleaning,
// 4 Wall Washing,
// 5 Blinds,
// 6 Baseboard,
// 7 Laundry,
// 12 Move in/out Cleaning,
// 13 Deep Clean,
// 14 Window Glass Cleaning - interior,
// 15 Window Blinds ,
// 17 Clean Refrigerator,
// 19 Post - Construction,
// 20 Post - Renovation,
// 21 Carpet Cleaning(Manually Quoted Separately)


export default function ExtraPicker3(props: ExtraPickerProps) {
  const [selectedExtras, setSelectedExtras] = useState<ExtraSelectItem[]>([]);
  const [availableExtras, setAvailableExtras] = useState<ExtraSelectItem[]>([]);

  useEffect(() => {
    get('/extras')
      .then((extras) => setAvailableExtras(extras))
      .catch((e) => { });
  }, []);

  const toggleExtra = (extra: ExtraSelectItem | Extra) => {
    if (!availableExtras) return;
    let newSelectedExtras = selectedExtras;
    selectedExtras.find((selectedExtra) => selectedExtra.id === extra.id)
      ? newSelectedExtras = selectedExtras.filter((selectedExtra) => selectedExtra.id !== extra.id)
      : newSelectedExtras = [...selectedExtras, { ...extra, checked: true, value: extra.id.toString(), label: extra.name ?? "" }]; ~``
    setSelectedExtras(newSelectedExtras);
    if (props.onSelectionChange) {
      props.onSelectionChange(newSelectedExtras, 0);
    }
  }

  /**
   * Sorts extras by their display size, grouping smaller items first
   * @param extras Array of extras to sort
   * @returns Sorted array with smaller items first
   */
  const sortExtrasBySize = (extras: ExtraSelectItem[]): ExtraSelectItem[] => {
    return [...extras].sort((a, b) => {
      const aSpansFull = (a.name?.length ?? 0) > 20 || a.adds_hours;
      const bSpansFull = (b.name?.length ?? 0) > 20 || b.adds_hours;
      return Number(aSpansFull) - Number(bSpansFull);
    });
  };

  return (
    <div className="w-full">
      {/* Close button if provided */}
      {props.closeButton && (
        <div className="mb-1.5 flex justify-end">
          {props.closeButton}
        </div>
      )}

      {/* Mosaic Grid */}
      <div className="grid auto-rows-[minmax(48px,auto)] grid-cols-2 gap-1.5 sm:grid-cols-3">
        {sortExtrasBySize(availableExtras).map((extra) => {
          const isSelected = selectedExtras.some((e) => e.id === extra.id);
          const shouldSpanFull = (extra.name?.length ?? 0) > 20 || extra.adds_hours;

          return (
            <button
              key={extra.id}
              onClick={() => toggleExtra(extra)}
              className={`flex items-center justify-between rounded-md border p-1.5 text-left transition-colors
                ${shouldSpanFull ? "col-span-2 sm:col-span-3" : ""}
                ${isSelected
                  ? "border-lime-600 bg-lime-50"
                  : "border-gray-200 hover:border-gray-300 hover:bg-gray-50"
                }
              `}
            >
              <div className="flex items-center space-x-2">
                <div className={`flex h-3.5 w-3.5 items-center justify-center rounded border
                  ${isSelected
                    ? "border-lime-600 bg-lime-600"
                    : "border-gray-300"
                  }
                `}>
                  {isSelected && <CheckIcon className="h-2 w-2 text-white" />}
                </div>
                <div>
                  <p className="text-xs font-medium leading-tight text-gray-900">{extra.name} (+${extra.cost})</p>
                </div>
              </div>
            </button>
          );
        })}
      </div>

    </div>
  );
}

