import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DrawerContext } from '../../context/DrawerContext';
import { del, get, post, put } from '../../helper/fetch';
import Pager from '../../components/Pager/Pager';
import CouponsTable from '../../components/CouponsTable/CouponsTable';
import CouponSearchForm from '../../components/CouponSearchForm/SearchForm';
import { toast } from 'react-toastify';
import { Coupon, CouponSearchParams, CouponsResponse } from "../../types/coupon";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export const Coupons: React.FC = () => {
  const history = useHistory();
  let location = useLocation();
  const { id } = useParams<{ id: string }>();

  const [page, setPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(20);

  const [error, setError] = useState(null);

  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [initialLoadParams, setInitialLoadParams] = useState({});
  const [searchParams, setSearchParams] = useState<CouponSearchParams | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setInitialLoadParams(params);
    }
    getPage(0);
  }, []);

  const { state, dispatch } = useContext(DrawerContext);

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const saveNewCoupon = async (data) => {
    dispatch({ type: 'SET_DRAWER_LOADING', loading: true });
    const resp = await post(`/coupons`, data);
    if (resp.status !== 201) {
      const body = await resp.json();
      toast.error('could not save new coupon');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'SET_DRAWER_ERROR', error: body.message });
      return body.error;
    } else {
      toast.success('saved new coupon');
      searchForCoupons(page, searchParams);
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'CLOSE_DRAWER' });
      return null;
    }
  };

  const openNewCouponDrawer = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'ADD_COUPON_FORM',
      data: {
        userId: id,
        propertyId: null,
        property: {},
        onSubmit: (data) => {
          saveNewCoupon(data);
        },
        onDelete: () => { }
      }
    });
  };

  function searchForCoupons(searchPage, searchData) {
    setSearchParams(searchData);
    setIsLoading(true);
    let urlParams = null;
    if (searchData) {
      setNumberPerPage(searchData.numberPerPage || 20);
      urlParams = new URLSearchParams({
        ...searchData,
        pageNumber: (searchPage || 0).toString()
      });
    } else {
      urlParams = new URLSearchParams({
        numberPerPage: (20).toString(),
        pageNumber: (searchPage || 0).toString()
      });
    }
    urlParams && history.push({ search: urlParams.toString() });
    get('/coupons?' + urlParams)
      .then((couponsResponse) => {
        if (!couponsResponse) throw new Error('result empty');
        setCoupons(couponsResponse.data);
        let numPerPage = searchData?.numberPerPage || numberPerPage;
        setNumberOfPages(
          Math.ceil((couponsResponse.total || 1) / numPerPage || 20)
        );
      })
      .catch((e) => {
        setError('could not fetch coupons');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getPage(pageNumber) {
    searchForCoupons(pageNumber, searchParams);
  }

  const openEditCouponDrawer = (couponId: number) => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'EDIT_COUPON_FORM',
      data: {
        couponId: couponId,
        onSubmit: (data) => {
          editCoupon(data);
        }
      }
    });
  };

  const editCoupon = async (data) => {
    dispatch({ type: 'SET_DRAWER_LOADING', loading: true });
    const resp = await put(`/coupons/${data.id}`, data);
    if (resp.status !== 200) {
      const body = await resp.json();
      toast.error('could not edit coupon');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'SET_DRAWER_ERROR', error: body.message });
      return body.error;
    } else {
      toast.success('saved coupon changes');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'CLOSE_DRAWER' });
      searchForCoupons(page, searchParams);
      return null;
    }
  };

  const deleteCoupon = async (couponId: number) => {
    dispatch({ type: 'SET_DRAWER_LOADING', loading: true });
    const resp = await del(`/coupons/${couponId}`);
    if (resp.status !== 200) {
      const body = await resp.json();
      toast.error('could not delete coupon');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      return body.error;
    } else {
      toast.success('deleted coupon');
      searchForCoupons(page, searchParams);
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'CLOSE_DRAWER' });
      return null;
    }
  };

  function renderCoupons(coupons: Coupon[]) {
    return (
      <CouponsTable
        coupons={coupons}
        onClick={(couponId) => {
          openEditCouponDrawer(couponId);
        }}
        onDelete={(couponId) => {
          deleteCoupon(couponId);
        }}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-6">
        <CouponSearchForm
          onSearch={(data) => {
            searchForCoupons(0, data);
          }}
          onClear={() => {
            setSearchParams(null);
            searchForCoupons(0, null);
          }}
          filterParams={initialLoadParams}
        />

        <div className="bg-white shadow-sm rounded-lg">
          <div className="p-4 border-b border-gray-200">
            <button
              onClick={openNewCouponDrawer}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Add New Coupon
            </button>
          </div>

          <div className="border-b border-gray-200">
            <Pager
              currentPage={page}
              numberOfPages={numberOfPages}
              onPageSelect={(newPage) => {
                setPage(newPage);
                getPage(newPage);
              }}
              onNext={() => {
                setPage(page + 1);
                getPage(page + 1);
              }}
              onPrev={() => {
                setPage(page - 1);
                getPage(page - 1);
              }}
            />
          </div>

          <div className="overflow-x-auto relative">
            {isLoading && (
              <div className="absolute inset-0 bg-white/50 z-10 flex items-center justify-center backdrop-blur-sm">
                <LoadingSpinner size="large" />
              </div>
            )}

            {coupons && coupons.length > 0 ? (
              <CouponsTable
                coupons={coupons}
                onClick={(couponId) => {
                  openEditCouponDrawer(couponId);
                }}
                onDelete={(couponId) => {
                  deleteCoupon(couponId);
                }}
              />
            ) : (
              <div className="p-8 text-center">
                <div className="text-gray-500">No coupons found</div>
              </div>
            )}
          </div>

          <div className="border-t border-gray-200">
            <Pager
              currentPage={page}
              numberOfPages={numberOfPages}
              onPageSelect={(newPage) => {
                setPage(newPage);
                getPage(newPage);
              }}
              onNext={() => {
                setPage(page + 1);
                getPage(page + 1);
              }}
              onPrev={() => {
                setPage(page - 1);
                getPage(page - 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
