import React, { useContext, useEffect, useState } from 'react';
import { withStyle } from 'baseui';
import {
  Col as Column,
  Grid,
  Row as Rows
} from '../../components/FlexBox/FlexBox';
import { get } from '../../helper/fetch';
import { DrawerContext } from '../../context/DrawerContext';
import Pager from '../../components/Pager/Pager';
import AddNewListCard from '../../components/AddNewListCard/AddNewListCard';
import BookingsTable from '../../components/BookingsTable/BookingsTable';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Booking } from '../../settings/bookingTypes';
import BookingSearchForm2, { BookingSearchFormState } from '../../components/BookingSearchForm2';
import { Dialog } from '@headlessui/react';
import NewBookingForm from '../NewBookingForm/NewBookingForm';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0
    }
  }
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center'
  }
}));

export type BookingsFilter = {
  id?: number;
  numberPerPage: number;
  pageNumber: number;
  status?: string | 'confirmed' | 'requested' | 'rejected' | 'paid' | 'accepted' | 'cancelled' | 'canceled';
  statuses?: string[];
  clientId?: string;
  cleanerId?: string;
  dateFrom?: string;
  dateTo?: string;
  clientFirstName?: string;
  cleanerFirstName?: string;
  clientLastName?: string;
  clientName?: string;
  cleanerName?: string;
  cleanerLastName?: string;
  couponCode?: string;
  propertyId?: number;
  sort?: string | 'date' | 'rdate' | 'name' | 'rname';
  region?: 'Calgary' | 'Edmonton' | 'Vancouver';
};


export default function Bookings() {
  const history = useHistory();
  let location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [selectedUrlId, setSelectedUrlId] = useState(id);

  const [page, setPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(50);

  const [error, setError] = useState(null);

  const [bookings, setBookigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [initialLoadParams, setInitialLoadParams] = useState({});
  const [searchParams, setSearchParams] = useState(null);

  const [isNewBookingModalOpen, setIsNewBookingModalOpen] = useState(false);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setInitialLoadParams(params);
    }
    // getPage(0);
  }, []);

  const { state, dispatch } = useContext(DrawerContext);

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const openNewBookingDrawer = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'ADD_BOOKING_FORM',
      data: {
        onSubmit: (data) => { },
        onDelete: () => { }
      }
    });
  };

  function searchForBookings(searchPage, searchData: BookingSearchFormState) {
    setIsLoading(true);
    setSearchParams(searchData);
    let urlParams = null;
    if (searchData) {
      const bookingsFilter: Record<string, string> = Object.fromEntries(
        Object.entries({
          clientName: searchData.client,
          cleanerName: searchData.cleaner,
          region: searchData.region,
          statuses: searchData.selectedStatuses?.join(","),
          dateFrom: searchData.dateFrom,
          dateTo: searchData.dateTo,
          numberPerPage: numberPerPage.toString(),
          pageNumber: (searchPage || 0).toString(),
          sort: searchData.sortSelected || "rdate"
        }).filter(([_, value]) => value != null && value !== "")
      );
      urlParams = new URLSearchParams(bookingsFilter);
    } else {
      urlParams = new URLSearchParams({
        numberPerPage: numberPerPage.toString(),
        pageNumber: (searchPage || 0).toString(),
        sortSelected: "rdate"
      });
    }
    urlParams && history.push({ search: urlParams.toString() });
    get('/bookings?' + urlParams)
      .then((bs) => {
        if (!bs) throw new Error('result empty');
        setBookigns(bs.bookings);
        setNumberOfPages(Math.ceil(bs.totalResults / numberPerPage));
      })
      .catch((e) => {
        setError('could not fetch bookings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getPage(pageNumber) {
    searchForBookings(pageNumber, searchParams);
  }

  function renderBookings(bookings: Booking[]) {
    return (
      <div className="relative">
        {isLoading && (
          <div className="absolute inset-0 bg-white/50 z-10 flex items-center justify-center backdrop-blur-sm">
            <LoadingSpinner size="large" />
          </div>
        )}
        <BookingsTable
          bookings={bookings}
          onClick={(bookingId) => {
            window.open(`/bookings/${bookingId}`);
            setSelectedUrlId(bookingId);
          }}
        />
      </div>
    );
  }

  const handleNewBookingSuccess = () => {
    setIsNewBookingModalOpen(false);
    // Refresh the bookings list
    searchForBookings(page, searchParams);
  };

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <BookingSearchForm2
            onMount={(searchData: BookingSearchFormState) => {
              searchForBookings(0, searchData);
            }}
            onSearch={(searchData: BookingSearchFormState) => {
              searchForBookings(0, searchData);
            }}
          />

          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {bookings && bookings.length !== 0 ? (
                <>
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />

                  <AddNewListCard
                    title="Add Booking "
                    onClick={() => setIsNewBookingModalOpen(true)}
                  />
                  {/* <AddNewListCard
                    title="Add New Group Booking"
                    onClick={() => {
                      window.open(`/groupbookings/new`);
                    }}
                  /> */}

                  {renderBookings(bookings)}
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      console.log(
                        `on next page ${page} number of pages ${numberOfPages}`
                      );
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      console.log(
                        `on prev page ${page} number of pages ${numberOfPages}`
                      );
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />
                </>
              ) : (
                <div className="p-6">No bookings found</div>
              )}
            </ul>
          </div>
        </Col>
      </Row>

      {/* New Booking Modal */}
      <Dialog
        open={isNewBookingModalOpen}
        onClose={() => setIsNewBookingModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-3xl rounded bg-white shadow-xl flex flex-col max-h-[90vh]">
            {/* Fixed Header */}
            <div className="p-6 border-b border-gray-200">
              <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                New Booking
              </Dialog.Title>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto p-6">
              <NewBookingForm
                onSuccess={handleNewBookingSuccess}
                selectedClientId="77zXGG1TLoPS8y3QfvvEmQ"
              />
            </div>

            {/* Fixed Footer */}
            <div className="p-6 border-t border-gray-200 bg-gray-50">
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                  onClick={() => setIsNewBookingModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Grid>
  );
}
