import { toast } from "react-toastify";

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


/**
 * Copies text to clipboard and shows a toast notification
 * @param text - The text to copy
 * @param label - Label to show in toast message
 */
export const copyToClipboard = (text: string, label: string): void => {
  void navigator.clipboard.writeText(text).then(() => {
    toast.info(`${label} copied to clipboard!`);
  }).catch((err: Error) => {
    console.error(`Failed to copy ${label.toLowerCase()}:`, err);
    toast.error(`Failed to copy ${label.toLowerCase()}`);
  });
};