// @ts-nocheck
import React, { useEffect, useRef, FC } from "react";
import Quill, { QuillOptions } from "quill";
import "quill/dist/quill.snow.css"; // For snow theme

type QuillEditorProps = {
  /** Initial content in HTML format */
  initialContent?: string;
  /** Initial content in Delta format */
  initialDelta?: DeltaStatic;
  initialHTML?: string;
  /** Callback function when content changes */
  onChange?: (content: { delta: DeltaStatic, html: string }) => void;
  /** Custom toolbar options */
  toolbarOptions?: QuillOptions["modules"]["toolbar"];
  /** Additional class names for the editor container */
  className?: string;
  /** Placeholder text when editor is empty */
  placeholder?: string;
  /** Read-only mode flag */
  readOnly?: boolean;
  onSave?: (content: { text: string, html: string, delta: DeltaStatic }) => void;
};

/**
 * A React component that wraps QuillJS editor
 * @component
 */
export const QuillEditor: FC<QuillEditorProps> = ({
  initialContent = "",
  initialDelta,
  initialHTML,
  onChange,
  onSave,
  toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 2 },],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
  ],
  className = "",
  placeholder = "Start writing...",
  readOnly = false,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillInstance = useRef<Quill | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Early return if editor is already initialized
    if (quillInstance.current) {
      return;
    }

    if (!editorRef.current) {
      return;
    }

    try {
      // Remove any existing toolbar before initialization
      const existingToolbar = containerRef.current?.querySelector(".ql-toolbar");
      if (existingToolbar) {
        existingToolbar.remove();
      }

      // Initialize Quill
      quillInstance.current = new Quill(editorRef.current, {
        modules: {
          toolbar: toolbarOptions,
        },
        placeholder,
        readOnly,
        theme: "snow",
      });


      // Set up change handler
      if (onChange) {
        quillInstance.current.on("text-change", () => {
          const delta = quillInstance.current?.getContents();
          const content = quillInstance.current?.root.innerHTML || "";
          onChange({ delta, html: content });
        });
      }
    } catch (error) {
      console.error("Error initializing Quill editor:", error);
    }

    // Cleanup
    return () => {
      if (quillInstance.current) {
        try {
          // Remove event listeners
          quillInstance.current.off("text-change");
          // Clear the instance
          quillInstance.current = null;
          // Remove toolbar
          const toolbar = containerRef.current?.querySelector(".ql-toolbar");
          if (toolbar) {
            toolbar.remove();
          }
        } catch (error) {
          console.error("Error cleaning up Quill editor:", error);
        }
      }
    };
  }, []); // Empty dependency array since we only want to initialize once

  // Handle updates to props
  useEffect(() => {
    if (!quillInstance.current) {
      return;
    }

    if (initialDelta) {
      try {
        const deltaContent = typeof initialDelta === "string"
          ? JSON.parse(initialDelta)
          : initialDelta;
        quillInstance.current.setContents(deltaContent);
      } catch (error) {
        console.error("Error parsing delta content:", error);
        // Fallback to empty content
        quillInstance.current.setContents([]);
      }
    } else if (initialContent) {
      quillInstance.current.root.innerHTML = initialContent;
    }
  }, [initialContent, initialDelta]);

  useEffect(() => {
    if (!quillInstance.current) {
      return;
    }

    quillInstance.current.enable(!readOnly);
  }, [readOnly]);

  const handleSave = () => {
    if (!quillInstance.current || !onSave) {
      return;
    }

    try {
      const delta = quillInstance.current.getContents();
      const html = quillInstance.current.root.innerHTML;
      const text = quillInstance.current.getText();
      onSave({ text, html, delta });
    } catch (error) {
      console.error("Error saving editor content:", error);
    }
  };

  return (
    <div ref={containerRef} className={`quill-editor-container ${className}`.trim()}>
      <div ref={editorRef} />
      {onSave && (
        <button
          type="button"
          onClick={handleSave}
          className="quill-editor-save-button bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
        >
          Save
        </button>
      )}
    </div>
  );
};